import { LegacyAny } from '@soracom/shared/core';

import { Component, HostBinding, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SoracomDateService } from '@soracom/shared-ng/date-service';
import { getFullDateTimeString } from '@soracom/shared/billings';
import { allSupportedCurrencyCodes } from '@soracom/shared/regulatory';

import { BillingsService } from '../../../../app/shared/billings/billings.service';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { AsyncOperationService } from '@user-console/user-console-common';

@Component({
  selector: 'div[appLatestBilling]',
  templateUrl: './latest-billing.component.html',
  host: {
    class: 'ds-group'
  }
})
export class LatestBillingComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  private uniqueIdentifierForCsvDownload: string;
  billData: LegacyAny;
  @HostBinding('class.--loading') isLoading = false;
  alertManager = new AlertsManager();

  currencyCodes = allSupportedCurrencyCodes;

  constructor(
    private asyncOperationService: AsyncOperationService,
    private billingsService: BillingsService,
    private soracomApi: SoracomApiService,
    private translate: TranslateService,
    private dateService: SoracomDateService
  ) {
  }

  ngOnInit(): void {
    this.uniqueIdentifierForCsvDownload = this.translate.instant(
      'billings.download_latest_csv'
    );

    this.fetchLatestBilling();
  }

  async fetchLatestBilling() {
    this.alertManager.clear();
    this.isLoading = true;

    try {
      this.billData = (await this.billingsService.getCachedLatestBill()).data;
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  }

  downloadCsv = () => {
    // ADD ASYNC SUPPORT: CALL SITE 1
    this.asyncOperationService.handleExportAsyncWithSyncFallback(
      this.uniqueIdentifierForCsvDownload,
      (asyncAllowed: LegacyAny) => {
        return this.soracomApi.getLatestBillsFileUrl(asyncAllowed);
      }
    );
  };

  get isDownloadOperationInProgress() {
    return this.asyncOperationService.isOperationAlreadyInProgress(
      this.uniqueIdentifierForCsvDownload
    );
  }

  getAmount() {
    return this.billData?.amount ?? 0;
  }

  get currencyUnit() {
    return this.billData?.currency;
  }

  shouldShowAmount() {
    if (this.isLoading) {
      return false;
    }

    const amount = this.getAmount();
    if (amount === undefined || amount === null) {
      return false;
    }

    return true;
  }

  getYearMonthHeader() {
    return (
      this.billData &&
      this.translate.instant('billings.latest.header_year_month', {
        date: this.writtenYearMonth,
      })
    );
  }

  getUpdatedDateTime() {
    return (
      this.billData &&
      this.translate.instant('billings.latest.date_time', {
        date: getFullDateTimeString(
          this.billData.lastEvaluatedTime, true
        ),
      })
    );
  }

  get dateRangeWarning() {
    if (this.billData) {
      const dateInfo = this.dateService.getDateDataFromFormat(this.billData.lastEvaluatedTime, 'YYYYMMDDhhmmss');
      return this.billingsService.getDateRangeWarningMessage(dateInfo.month, dateInfo.finalDay);
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  get writtenYearMonth(): string {
    if (this.billData) {
      const dateInfo = this.dateService.getDateDataFromFormat(this.billData.lastEvaluatedTime, 'YYYYMMDDhhmmss');
      return this.billingsService.getWrittenYearMonth(dateInfo.month, dateInfo.year);
    }
  }

  currencySymbolTranslated() {
    return this.billingsService.currencySymbolTranslated({currency: this.currencyUnit});
  }
}
