<p class="ds-tag --pinned"><span>{{'billingDashboard.serviceBilling.title' | translate}}</span></p>
<div class="ds-text --xxsmall">{{serviceBillingData?.updatedTimeLabel}}</div>
<div class="ds-text --uppercase --xxsmall --icon-calendar-dates --color-celeste-dark">
    {{dateRangeWarning}}
</div>
<div class="ds-cols --middle --no-gap">
    <div class="ds-heading --large --color-celeste-dark" [ngClass]="serviceIconClass">{{serviceId}}</div>
    <div class="ds-heading --large ds-span --end">{{serviceBillingData?.totalAmountPriceLabel}}</div>
</div>
<hr>
<table stTable [removeHeader]="true" class="ds-datatable --condensed">
    <ng-container stColumnDef="name">
        <td class="--max" *stCell="let item">
            <div>{{ item.billItemName }}</div>
        </td>
    </ng-container>

    <ng-container stColumnDef="priceLabel">
        <td class="ds-text --xsmall --no-wrap --right" *stCell="let item">
            <div>{{ item.priceLabel }}</div>
        </td>
    </ng-container>

    <tr *stRowDef="let item" [stRow]="item"></tr>
</table>
<div *ngIf="serviceBillingData?.serviceCostBreakdown?.length ?? -1 > maxRows" class="ds-cols --gap-xsmall --middle">
    <st-paging-status class="ds-span --end --xsmall"></st-paging-status>
    <button class="--xsmall" stPrevButton></button>
    <button class="--xsmall" stNextButton></button>
</div>
