import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { LegacyAny } from '@soracom/shared/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureVisibilityService, getOperatorData } from '@soracom/shared/data-access-auth';
import { CountryService } from '../../../../app/shared/components/country.service';
import { UiTab } from '../../soracom-ui/ui-tabs/UiTab';
import { UiTabs } from '../../soracom-ui/ui-tabs/UiTabs';
import { PaymentStatementService } from '../service/payment-statement.service';
import { PaymentMethodService } from '../../soracom-payment/payment-method-service.service';
import { Logger } from '@soracom/shared/logger';
import { PaymentMethod } from 'apps/user-console/app/shared/core/payment_method';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const billingPageRoutes = {
  'BILLING_DASHBOARD': 'billing_dashboard',
  'PAYMENT_HISTORY': 'payment_history',
  'PAYMENT_SETTINGS': 'payment_settings',
  'BILLING_ALERT': 'billing_alert'
}
type BillingPageKeys = keyof typeof billingPageRoutes;

@Component({
  selector: 'app-billing-page',
  templateUrl: './billing-page.component.html',
})
export class BillingPageComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  /**Map: billing-page-key -> tab-index */
  tabIndexStore: Record<BillingPageKeys, number> = {
    // @ts-expect-error (legacy code incremental fix)
    BILLING_DASHBOARD: undefined,
    // @ts-expect-error (legacy code incremental fix)
    PAYMENT_HISTORY: undefined,
    // @ts-expect-error (legacy code incremental fix)
    PAYMENT_SETTINGS: undefined,
    // @ts-expect-error (legacy code incremental fix)
    BILLING_ALERT: undefined
  };
  tabsContent = new UiTabs();
  tabs: UiTab[] = [];
  public paymentMethod?: PaymentMethod;
  public loadingPaymentMethod = false;

  constructor(
    private featureVisibilityService: FeatureVisibilityService,
    private countryService: CountryService,
    private paymentStatementService: PaymentStatementService,
    private router: Router,
    private route: ActivatedRoute,
    private paymentMethodService: PaymentMethodService,
    private logger: Logger
  ) {
    this.initTabs();
    this.tabsContent.tabIdPrefix = 'billing-tabs';
    this.tabsContent.tabStyle = 'vertical';
    this.tabsContent.classes = ['billing-tabs'];
    this.respondToRouteUrl();
  }

  private respondToRouteUrl() {
    this.route.url.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(route => {
      const pageKey = this.getPageKeyFromRoute(route[0].path);
      // @ts-expect-error (legacy code incremental fix)
      this.tabsContent.selectedTabIndex = this.tabIndexStore[pageKey];
    })
  }

  private getPageKeyFromRoute(route: string) {
    for (const key in billingPageRoutes) {
      if ((billingPageRoutes as LegacyAny)[key] === route) {
        return key;
      }
    }
  }

  ngOnInit(): void {
    this.loadingPaymentMethod = true;
    this.paymentMethodService
    .fetchCurrentPaymentMethod()
    .then((data: PaymentMethod | undefined) => {
      this.paymentMethod = data; //This is just used to know whether to display payer information. If the user has no payment method, payer information does not need to appear
    })
    .catch((error) => {
      if (error.status !== 404) {
        // 404 is a normal expected thing when user doesn't have payment method
        this.logger.debug('Error fetching payment service', error);
      }
    }).finally( () => {
      this.loadingPaymentMethod = false;
    })
  }

  initTabs() {
    let count = 0;

    //Billing Dashboard
    this.tabs.push(UiTab.configure((uiTab) => {
      uiTab.labelId = 'payments.tabs.billing_dashboard';
      uiTab.tabHeaderClasses = ['x-tab-billing-dashboard'];
      uiTab.tabContentIndentType = 'no-indent';
      uiTab.onTabSelected = () => {
        this.onTabSelected(billingPageRoutes.BILLING_DASHBOARD);
      };
    }));
    this.tabIndexStore['BILLING_DASHBOARD'] = count++;

    //Billings
    this.tabs.push(UiTab.configure((uiTab) => {
      uiTab.labelId = 'payments.tabs.billings';
      uiTab.tabHeaderClasses = ['x-tab-bills'];
      uiTab.onTabSelected = () => {
        this.onTabSelected(billingPageRoutes.PAYMENT_HISTORY);
      };
    }));
    this.tabIndexStore['PAYMENT_HISTORY'] = count;
    count++;

    //Payment Methods
    this.tabs.push(UiTab.configure((uiTab) => {
      uiTab.labelId = 'payments.tabs.payment_methods';
      uiTab.tabHeaderClasses = ['x-tab-payment-method'];
      uiTab.onTabSelected = () => {
        this.onTabSelected(billingPageRoutes.PAYMENT_SETTINGS);
      };
    }));
    this.tabIndexStore['PAYMENT_SETTINGS'] = count++;

    //Event Handler
    if (this.featureVisibilityService.isEnabled(
      'eventHandlerMonthlyChargeRule'
    )) {
      this.tabs.push(UiTab.configure((uiTab) => {
        uiTab.labelId = 'payments.tabs.billing_alert';
        uiTab.tabHeaderClasses = ['x-tab-billing-alert'];
        uiTab.onTabSelected = () => {
          this.onTabSelected('/billing_alert');
        };
      }));
      this.tabIndexStore['BILLING_ALERT'] = count++;
    }
  }

  onTabSelected(path: string) {
    this.moveToPage(path);
  }

  isEuCustomer() {
    // Mason 2017-02-27: FIXME: remove JwtService and CountryService from here, and make a directive/component that shows a message depending on country of customer like this.
    const countryCode = getOperatorData().getHomeCountry();

    return (
      countryCode &&
      this.countryService.euMemberCountries().includes(countryCode)
    );
  }

  shouldShowInvoicesTab() {
    return this.featureVisibilityService.isEnabled('invoices');
  }

  getTabIndex(page: string) {
    return (this.tabIndexStore as LegacyAny)[page];
  }

  allowPaymentStatements() {
    return this.paymentStatementService.paymentStatementsAllowed() && !this.loadingPaymentMethod;
  }

  moveToPage(route: string) {
    this.router.navigate([`/billings/${route}`]);
  };
}
