import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SoracomDateService } from '@soracom/shared-ng/date-service';
import { BillingApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { getFullDateTimeString, getFullDateTimeStringFromUnix } from '@soracom/shared/billings';
import { CurrencyCode } from '@soracom/shared/regulatory';
import { SoracomBillingServiceId } from '@soracom/shared/soracom-platform';
import { keyBy } from 'lodash-es';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { PriceAmountPipe } from '../../core/pipes/PriceAmountPipe';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { SoracomApiParams } from '@user-console/legacy-soracom-api-client';

export const minDisplayAmount: Record<CurrencyCode, number> = {
  USD: 0.01,
  JPY: 1,
  EUR: 0.01,
} as const;

export const lessThanMinLabel: Record<CurrencyCode, string> = {
  USD: '< $0.01',
  JPY: '< ¥1',
  EUR: '< €0.01',
} as const;

export const lessThanMinLabelNegative: Record<CurrencyCode, string> = {
  USD: '-(< $0.01)',
  JPY: '-(< ¥1)',
  EUR: '-(< €0.01)',
};

export const secondaryServiceIdMap: Partial<Record<SoracomBillingServiceId, boolean>> = {
  Other: true,
  FreeTier: true,
  Discount: true,
  Coupon: true,
};

@Injectable()
export class BillingDashboardDataService {
  private readonly numberOfPreviousMonths = 3;
  private _billingMonthList: BillingDashboardDateData[] = [];
  private yearMonthMap: { [yearMonth: string]: BillingDashboardDateData } = {};
  public currencyUnit$: ReplaySubject<CurrencyCode> = new ReplaySubject<CurrencyCode>(1);
  private _billingHistoryPromise: Promise<MonthRecentBillingPanelData[]>;
  private _serviceCostBreakdownApiResponsePromise: Promise<MonthServiceCostBreakdownResponseData[]>; //billingByService and serviceBilling get their data from the same initial API call, stored in serviceCostBreadownPromise
  private _billingByServicePromise: Promise<MonthBillingByServicePanelData[]>;
  private _serviceBillingPromise: Promise<ServiceToServiceBillingPanelDataMap[]>;
  private _costPerSimPromise: Promise<MonthCostPerSimPanelData[]>;

  constructor(
    private soracomApiService: SoracomApiService,
    private priceAmountPipe: PriceAmountPipe,
    private dateService: SoracomDateService,
    private billingApiService: BillingApiService,
    private translateService: TranslateService,
  ) {
    this.initAllBillingMonthData();
    this._billingHistoryPromise = this.loadBillingHistory();
    this._serviceCostBreakdownApiResponsePromise = this.loadServiceCostBreakdownForAllMonths();
    this._billingByServicePromise = this.loadBillingByServiceDataForAllMonths();
    this._serviceBillingPromise = this.loadDetailedServiceBillingDataForAllMonths();
    this._costPerSimPromise = this.loadCostPerSimBreakdownForAllMonths();
  }

  public get billingMonthList(): BillingDashboardDateData[] {
    return this._billingMonthList;
  }

  public getDateDataForBillingMonth(yearMonth: string): BillingDashboardDateData {
    return this.yearMonthMap[yearMonth];
  }

  public getCurrencyUnit(): Promise<string> {
    return firstValueFrom(this.currencyUnit$);
  }

  /**
   * Returns an observable of the billing month master data for all year months, that will complete once the billing history request has finished
   */
  public getRecentBillingPanelData(): Promise<MonthRecentBillingPanelData[]> {
    return this._billingHistoryPromise;
  }

  private initAllBillingMonthData() {
    //first get the current date data
    const currentMonthData = this.dateService.getCurrentDateData();
    this._billingMonthList.push(currentMonthData);
    this.yearMonthMap[currentMonthData.yearMonth] = currentMonthData;
    //then get the previous months
    for (let i = 1; i <= this.numberOfPreviousMonths; i++) {
      const monthData = this.dateService.getPreviousMonthData(currentMonthData.yearMonth, i);
      this._billingMonthList.push(monthData);
      this.yearMonthMap[monthData.yearMonth] = monthData;
    }
  }

  private loadBillingHistory(): Promise<MonthRecentBillingPanelData[]> {
    return this.soracomApiService.getBills().then((billingHistory: LegacyAny) => {
      const billingHistoryData = billingHistory.data.billList;
      if (billingHistoryData[0]?.currency) this.currencyUnit$.next(billingHistoryData[0].currency);
      return this.initBillingAmounts(billingHistoryData);
    });
  }

  public getLatestBillTimeStampLabel(): Promise<string> {
    return this.billingApiService.getLatestBilling({}).then((response: LegacyAny) => {
      return response.data.lastEvaluatedTime
        ? this.translateService.instant('billings.latest.date_time', {
            date: getFullDateTimeString(response.data.lastEvaluatedTime, true),
          })
        : '';
    });
  }

  private initBillingAmounts(billingHistory: MonthlyBill[]): MonthRecentBillingPanelData[] {
    const yearMonthToMonthlyBillMap = keyBy(billingHistory, 'yearMonth');
    return this._billingMonthList.map((monthData) => {
      const srcData = yearMonthToMonthlyBillMap[monthData.yearMonth];
      const monthlyBillData = {
        amount: srcData?.amount ?? 0,
        discountAmount: srcData?.appliedCouponAmount ?? 0,
        currency: srcData?.currency,
      };
      return {
        ...monthData,
        monthlyBillData,
      };
    });
  }

  /**
   * Returns an observable of the billing month master data for the given yearMonth that will emit when the entire service cost breakdown data has finished loading
   */
  public getMonthBillingByService(yearMonth: string): Promise<MonthBillingByServicePanelData> {
    // @ts-expect-error (legacy code incremental fix)
    return this._billingByServicePromise.then((list) => list.find((monthData) => monthData.yearMonth === yearMonth));
  }

  private loadServiceCostBreakdownForAllMonths(): Promise<MonthServiceCostBreakdownResponseData[]> {
    const apiParams: SoracomApiParams = {
      path: `v1/bills/summaries/bill_items`,
    };
    return this.soracomApiService.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return response.data;
    });
  }

  private loadBillingByServiceDataForAllMonths(): Promise<MonthBillingByServicePanelData[]> {
    return this._serviceCostBreakdownApiResponsePromise.then((response: LegacyAny) => {
      return this.parseCostPerServiceForAllMonths(response);
    });
  }

  /** Derive total month amounts for each service category into BillingByServicePanelData */
  private parseCostPerServiceForAllMonths(
    response: MonthServiceCostBreakdownResponseData[],
  ): MonthBillingByServicePanelData[] {
    if (response[0]?.currency) this.currencyUnit$.next(response[0].currency);
    //init the bilingByServiceDataList and map
    const yearMonthToServiceCostBreakdownMap = keyBy(response, 'yearMonth');
    return this._billingMonthList.map((monthData) => {
      const monthServiceCostBreakdownData = yearMonthToServiceCostBreakdownMap[monthData.yearMonth];
      return {
        ...monthData,
        updatedTimeLabel: monthServiceCostBreakdownData?.updatedTime
          ? this.updatedTimeLabel(monthServiceCostBreakdownData.updatedTime)
          : '',
        serviceCostBreakdown: monthServiceCostBreakdownData
          ? this.calcServiceTotalCostData(monthServiceCostBreakdownData)
          : [],
      };
    });
  }

  /** Derive total month amounts for each service category.  This is done by totaling the individual amounts listed in each bill item of each service category
   * @param monthServiceBreakdown - the data returned from the API for a given month
   */
  private calcServiceTotalCostData(
    monthServiceBreakdown: MonthServiceCostBreakdownResponseData,
  ): ServiceTotalCostData[] {
    const serviceAmountMap = monthServiceBreakdown?.costBreakdownList.reduce((map, item) => {
      const key = item.billItemCategory;
      map.set(key, (map.get(key) ?? 0) + item.amount);
      return map;
    }, new Map<SoracomBillingServiceId, number>());

    return (
      [...serviceAmountMap.entries()]
        .map(([service, amount]) => ({ service, amount }))
        //some values are negative, like coupons.  We do not want to display these, only the positive values
        .filter((item) => item.amount >= 0)
        .sort((a: LegacyAny, b: LegacyAny) => b.amount - a.amount)
    );
  }

  /**
   * Returns a promise containing costPerSim data added to the basic date data for the given month.
   */
  public getMonthCostPerSim(yearMonth: string): Promise<MonthCostPerSimPanelData> {
    // @ts-expect-error (legacy code incremental fix)
    return this._costPerSimPromise.then((monthDataList) =>
      monthDataList.find((monthData) => monthData.yearMonth === yearMonth),
    );
  }

  private loadCostPerSimBreakdownForAllMonths(): Promise<MonthCostPerSimPanelData[]> {
    const apiParams: SoracomApiParams = {
      path: `v1/bills/summaries/sims`,
    };
    return this.soracomApiService.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return this.parseCostPerSimResponseForAllMonths(response.data);
    });
  }

  private parseCostPerSimResponseForAllMonths(response: MonthCostPerSimResponseData[]): MonthCostPerSimPanelData[] {
    if (response[0]?.currency) {
      this.currencyUnit$.next(response[0].currency);
    }
    const costPerSimMap = keyBy(response, 'yearMonth');
    return this._billingMonthList.map((billingMonth) => ({
      ...billingMonth,
      updatedTimeLabel: costPerSimMap[billingMonth.yearMonth]?.updatedTime
        ? this.updatedTimeLabel(costPerSimMap[billingMonth.yearMonth]?.updatedTime)
        : '',
      costPerSim: costPerSimMap[billingMonth.yearMonth]?.costBreakdownList ?? [],
      highestSimCost: costPerSimMap[billingMonth.yearMonth]?.costBreakdownList[0].amount ?? 0,
    }));
  }

  public getMonthServiceBilling(
    yearMonth: string,
    serviceId: SoracomBillingServiceId,
  ): Promise<MonthServiceBillingPanelData> {
    // @ts-expect-error (legacy code incremental fix)
    return this._serviceBillingPromise.then((monthDataList) =>
      monthDataList.find((monthDataMap) => monthDataMap.get(serviceId)?.yearMonth === yearMonth)?.get(serviceId),
    );
  }

  private loadDetailedServiceBillingDataForAllMonths(): Promise<ServiceToServiceBillingPanelDataMap[]> {
    return this._serviceCostBreakdownApiResponsePromise.then((responseAllMonths) => {
      const yearMonthToServiceCostBreakdownMap = keyBy(responseAllMonths, 'yearMonth');
      return this._billingMonthList.map((monthData) => {
        const monthServiceCostBreakdownData = yearMonthToServiceCostBreakdownMap[monthData.yearMonth];
        return this.parseDetailedServiceBillingFromApiResponse(monthServiceCostBreakdownData);
      });
    });
  }

  private parseDetailedServiceBillingFromApiResponse(
    serviceCostBreakdownApiData: MonthServiceCostBreakdownResponseData,
  ): ServiceToServiceBillingPanelDataMap {
    if (!serviceCostBreakdownApiData?.costBreakdownList) return new Map();
    const updatedTimeLabel = serviceCostBreakdownApiData.updatedTime
      ? this.updatedTimeLabel(serviceCostBreakdownApiData.updatedTime)
      : '';
    return serviceCostBreakdownApiData.costBreakdownList.reduce((map, item) => {
      const key = item.billItemCategory;
      const runningTotal = (map.get(key)?.totalAmount ?? 0) + item.amount;
      map.set(key, {
        ...this.getDateDataForBillingMonth(serviceCostBreakdownApiData.yearMonth),
        serviceId: key,
        updatedTimeLabel: updatedTimeLabel,
        totalAmount: runningTotal,
        totalAmountPriceLabel: this.determineLabelForPriceAmount(runningTotal, serviceCostBreakdownApiData.currency),
        serviceCostBreakdown: [
          ...(map.get(key)?.serviceCostBreakdown ?? []),
          {
            amount: item.amount,
            billItemName: item.billItemName,
            priceLabel: this.determineLabelForPriceAmount(item.amount, serviceCostBreakdownApiData.currency),
          },
        ],
      });
      return map;
    }, new Map<SoracomBillingServiceId, MonthServiceBillingPanelData>());
  }

  /**Will return a rounded currency value (using priceAmountPipe).  Values less than a preset minimum will be returned in special format (ex: '< $1')*/
  public determineLabelForPriceAmount(amount: number, currencyUnit: CurrencyCode) {
    if (Math.abs(amount) < minDisplayAmount[currencyUnit]) {
      return amount > 0 ? lessThanMinLabel[currencyUnit] : lessThanMinLabelNegative[currencyUnit];
    }
    return this.priceAmountPipe.transform(amount, currencyUnit);
  }

  /**Returns a list of billed serviceIds for passed yearMonth.  Order is highest billingAmount to lowest */
  public getMonthBilledServiceList(yearMonth: string): Promise<MonthBilledServiceLists> {
    return this._serviceCostBreakdownApiResponsePromise.then((response: LegacyAny) => {
      const monthBilledServiceLists: MonthBilledServiceLists = {
        mainServices: [],
        otherServices: [],
      };
      const monthServiceCostBreakdownData = response.find((monthData: LegacyAny) => monthData.yearMonth === yearMonth);
      const unfilteredServiceIdList = monthServiceCostBreakdownData?.costBreakdownList.map(
        (item: LegacyAny) => item.billItemCategory,
      );
      unfilteredServiceIdList
        ?.filter((item: LegacyAny, index: LegacyAny) => unfilteredServiceIdList.indexOf(item) === index)
        .reduce((monthBilledServiceLists: MonthBilledServiceLists, serviceName: SoracomBillingServiceId) => {
          if (secondaryServiceIdMap[serviceName]) {
            monthBilledServiceLists.otherServices.push(serviceName);
          } else {
            monthBilledServiceLists.mainServices.push(serviceName);
          }
          return monthBilledServiceLists;
        }, monthBilledServiceLists);
      monthBilledServiceLists.mainServices.sort();
      monthBilledServiceLists.otherServices.sort();
      return monthBilledServiceLists;
    });
  }

  private updatedTimeLabel(updatedTime: number): string {
    return this.translateService.instant('billings.latest.date_time', {
      date: getFullDateTimeStringFromUnix(updatedTime),
    });
  }
}

export interface MonthRecentBillingUiData {
  amount: number;
  currency: CurrencyCode;
  discountAmount: number;
}

export interface MonthRecentBillingPanelData extends BillingDashboardDateData {
  monthlyBillData: MonthRecentBillingUiData;
}

export interface BillingDashboardDateData {
  month: number;
  monthName: string; //pre-translated for convenience
  year: number;
  yearMonth: string;
  finalDay: number;
  totalDays: number;
}

export interface MonthlyBill {
  amount: number;
  appliedCouponAmount?: number;
  currency: CurrencyCode;
  state: string;
  yearMonth: string;
}

export interface MonthServiceCostBreakdownResponseData {
  yearMonth: string;
  currency: CurrencyCode;
  updatedTime: number;
  costBreakdownList: Array<{
    amount: number;
    billItemName: string;
    billItemCategory: SoracomBillingServiceId;
  }>;
}

export interface ServiceTotalCostData {
  amount: number;
  service: SoracomBillingServiceId;
}

export interface MonthBillingByServicePanelData extends BillingDashboardDateData {
  serviceCostBreakdown: ServiceTotalCostData[];
  updatedTimeLabel: string;
}

export interface MonthCostPerSimResponseData {
  yearMonth: string;
  currency: CurrencyCode;
  updatedTime: number;
  costBreakdownList: Array<CostPerSimData>;
}

export interface CostPerSimData {
  simId: string;
  amount: number;
}

export interface MonthCostPerSimPanelData extends BillingDashboardDateData {
  costPerSim: CostPerSimData[];
  highestSimCost: number;
  updatedTimeLabel: string;
}

export interface MonthServiceBillingPanelData extends BillingDashboardDateData {
  serviceId: SoracomBillingServiceId;
  serviceCostBreakdown: ServiceDetailedBillingData[];
  totalAmount: number;
  totalAmountPriceLabel: string;
  updatedTimeLabel: string;
}

export type ServiceToServiceBillingPanelDataMap = Map<SoracomBillingServiceId, MonthServiceBillingPanelData>;

export interface ServiceDetailedBillingData {
  amount: number;
  billItemName: string;
  priceLabel: string;
}

export interface MonthBilledServiceLists {
  mainServices: SoracomBillingServiceId[];
  otherServices: SoracomBillingServiceId[];
}
