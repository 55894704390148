import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PayerInformation } from '../../../../../app/shared/core/payer_information';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { UiInput } from '@soracom/shared-ng/soracom-ui-legacy';
import { PaymentStatementService } from '../../service/payment-statement.service';

export interface EditPayerInfoOutput {
  wasSaved: boolean;
}

@Component({
  templateUrl: './edit-payer-info.component.html',
})
export class EditPayerInfoComponent
  extends DsModalContentBase<PayerInformation, EditPayerInfoOutput>
  implements OnInit
{
  alertManager = new AlertsManager();
  footerButtonBar = new UiButtonBar();
  // @ts-expect-error (legacy code incremental fix)
  formGroup: FormGroup;
  companyName = new UiInput();
  department = new UiInput();
  fullName = new UiInput();

  readonly data = this.getInput();

  isSaving = false;

  constructor(private paymentStatementsService: PaymentStatementService) {
    super();
    this.setupForm();

    this.footerButtonBar = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'payment_statements.payer_information.cancel';
          button.classes = ['x-cancel-payer-information'];
          button.onClick = () => this.close();
        }),
        UiButton.configure((button) => {
          button.titleId = 'payment_statements.payer_information.save';
          button.classes = ['x-save-payer-information'];
          button.buttonStyle = 'primary';
          button.isDisabled_ƒ = () => !this.canSubmit();
          button.onClick = this.save;
        }),
      ];
    });

    this.setupInputs();
  }

  setupForm() {
    this.formGroup = new FormGroup({
      companyName: new FormControl(this.data?.companyName || '', [Validators.maxLength(100)]),
      department: new FormControl(this.data?.department || '', [Validators.maxLength(100)]),
      fullName: new FormControl(this.data?.fullName || '', [Validators.maxLength(100)]),
    });
  }

  setupInputs() {
    this.companyName = UiInput.configure((input) => {
      input.label = LegacyTextContent.translation('payment_statements.payer_information.company_name');
      // @ts-expect-error (legacy code incremental fix)
      input.formControl = this.formGroup.get('companyName');
      input.id = 'company-name';
      input.maxLength = 100;
      input.required = true;
    });

    this.department = UiInput.configure((input) => {
      input.label = LegacyTextContent.translation('payment_statements.payer_information.department');
      // @ts-expect-error (legacy code incremental fix)
      input.formControl = this.formGroup.get('department');
      input.id = 'department';
      input.maxLength = 100;
    });

    this.fullName = UiInput.configure((input) => {
      input.label = LegacyTextContent.translation('payment_statements.payer_information.full_name');
      // @ts-expect-error (legacy code incremental fix)
      input.formControl = this.formGroup.get('fullName');
      input.id = 'full-name';
      input.maxLength = 100;
    });
  }

  ngOnInit(): void {}

  canSubmit = () => {
    return this.formGroup.valid && this.formGroup.dirty && !this.isSaving;
  };

  save = async () => {
    this.isSaving = true;
    this.alertManager.clear();

    try {
      await this.paymentStatementsService.updatePayerInformation(this.formGroup.value);
      this.close({ wasSaved: true });
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isSaving = false;
    }
  };
}
