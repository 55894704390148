import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { FeatureVisibilityService, getOperatorData } from '@soracom/shared/data-access-auth';
import { LegacyBaseSoracomApiService, SoracomApiParams } from '@user-console/legacy-soracom-api-client';
import { PayerInformation } from '../../../../app/shared/core/payer_information';
import { PaymentStatement } from '../../../../app/shared/core/payment_statement';

@Injectable({
  providedIn: 'root',
})
export class PaymentStatementService {
  constructor(
    private soracomApi: LegacyBaseSoracomApiService,
    private featureVisibilityService: FeatureVisibilityService,
  ) {}

  fetchPayerInformation(): Promise<PayerInformation> {
    const apiParams: SoracomApiParams = {
      path: '/v1/payment_statements/payer_information',
    };

    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      return new PayerInformation(res.data);
    });
  }

  updatePayerInformation(data: PayerInformation): Promise<unknown> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/payment_statements/payer_information',
      contentType: 'application/json',
      body: data,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  fetchPaymentStatements(): Promise<PaymentStatement[]> {
    const apiParams: SoracomApiParams = {
      path: '/v1/payment_statements',
    };

    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      return res.data.paymentStatements;
    });
  }

  exportPaymentStatement(id: string, async: boolean) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/payment_statements/${id}/export`,
      contentType: 'application/json',
    };
    if (async) {
      apiParams.query = { export_mode: 'async' };
    }

    return this.soracomApi.callApiWithToken(apiParams);
  }

  /** Due to compliance regulations, payment statements functionality will only be available for customers based in Japan. Based in japan is defined as JWT homeCountry = 'JP', but there is a special `esmPaymentStatements` feature flag that can be used to enable the feature for customers outside of Japan.
   */
  paymentStatementsAllowed(): boolean {
    if (getOperatorData().getHomeCountry() === 'JP') {
      return true;
    } else if (this.featureVisibilityService.isEnabled('esmPaymentStatements')) {
      return true;
    }
    return false;
  }
}
