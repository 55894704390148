import { LegacyAny } from '@soracom/shared/core';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QueryApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import {
  generateStInMemoryServiceProviders,
  StInMemoryPaginatorService,
  StInMemoryTableDataProviderService,
  StLoadingIndicator,
  StRemoteApiErrorHandler,
  StTableDataProvider,
} from '@soracom/shared-ng/ui-super-table';
import { parseApiErrorReason, useResolveApiErrorReason } from '@soracom/shared-ng/util-common';
import { getLoginUserData } from '@soracom/shared/data-access-auth';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { isSamUserHasInsufficientPrivilegeError } from 'apps/user-console/src/app/shared/samUserErrorCodes';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  BillingDashboardDataService,
  BillingDashboardDateData,
  CostPerSimData,
  MonthCostPerSimPanelData,
} from '../../billing-dashboard-data.service';
import { CostPerSimDataService } from './cost-per-sim-data.service';
import { ExtendedSimApiService } from '@soracom/shared-ng/sim-services';

@Component({
  selector: 'a[costPerSimPanel], div[costPerSimPanel]',
  templateUrl: `cost-per-sim-panel.component.html`,
  host: {
    class: 'ds-card --panel --indent-small ds-span --12 x-billing-dashboard-cost-per-sim-panel',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StInMemoryTableDataProviderService,
    ...generateStInMemoryServiceProviders(StInMemoryTableDataProviderService, {
      pagination: {
        itemsPerPageOptions: [10, 20, 50, 100],
        initialValue: 10,
      },
    }),
    {
      provide: StLoadingIndicator,
      useExisting: CostPerSimPanelComponent,
    },
    {
      provide: StRemoteApiErrorHandler,
      useExisting: CostPerSimPanelComponent,
    },
    {
      provide: StTableDataProvider,
      useFactory: () =>
        new CostPerSimDataService(
          inject(StInMemoryPaginatorService),
          inject(StLoadingIndicator),
          inject(StRemoteApiErrorHandler),
          inject(ExtendedSimApiService),
        ),
    },
  ],
})
export class CostPerSimPanelComponent implements OnInit, StLoadingIndicator {
  private resolveApiErrorReason = useResolveApiErrorReason();
  // @ts-expect-error (legacy code incremental fix)
  private selectedBillingMonth: BillingDashboardDateData;
  private yearMonthCostPerSimDataCache = new Map<string, MonthCostPerSimPanelData>();
  @Input() set selectedYearMonth(selectedYearMonth: string) {
    this.onSelectedYearMonthChange(selectedYearMonth);
  }
  // @ts-expect-error (legacy code incremental fix)
  public selectedUpdatedTime: string;

  // @ts-expect-error (legacy code incremental fix)
  public currencyUnit: string;

  loading$ = new BehaviorSubject<boolean>(true);

  @HostBinding('attr.data-ds-message') panelMessage: string | null = null;

  constructor(
    private billingDashboardService: BillingDashboardDataService,
    private tableDataProvider: StInMemoryTableDataProviderService<CostPerSimData>,
    private translate: TranslateService,
    private cdf: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.billingDashboardService.getCurrencyUnit().then((currencyUnit) => {
      this.currencyUnit = currencyUnit;
      this.cdf.markForCheck();
    });
  }

  private onSelectedYearMonthChange(selectedYearMonth: string) {
    this.clearApiErrors();
    this.loading$.next(true);
    this.selectedBillingMonth = this.billingDashboardService.getDateDataForBillingMonth(selectedYearMonth);
    const cachedCostPerSimData = this.yearMonthCostPerSimDataCache.get(selectedYearMonth);
    if (cachedCostPerSimData) {
      this.updateData(selectedYearMonth);
      this.loading$.next(false);
    } else {
      this.loadCostPerSimDataForMonth(this.selectedBillingMonth);
    }
  }

  private loadCostPerSimDataForMonth(billingMonth: BillingDashboardDateData) {
    this.billingDashboardService
      .getMonthCostPerSim(this.selectedBillingMonth.yearMonth)
      .then((billingMonthData) => {
        this.loading$.next(false);
        if (this.selectedBillingMonth.yearMonth === billingMonth.yearMonth) {
          this.yearMonthCostPerSimDataCache.set(billingMonth.yearMonth, billingMonthData);
          this.updateData(billingMonth.yearMonth);
        }
      })
      .catch((err: LegacyAny) => {
        this.loading$.next(false);
        this.handleApiError(err);
      });
  }

  handleApiError(error: any): void {
    if (isSamUserHasInsufficientPrivilegeError(error)) {
      this.panelMessage = this.translate.instant('billingDashboard.costPerSim.samPermissionError', {
        name: getLoginUserData().emailOrUserName,
      });
      return;
    }
    this.panelMessage = this.resolveApiErrorReason(error);
  }

  clearApiErrors(): void {
    this.panelMessage = null;
  }

  simScaleStyle(simCost: number): string {
    const highestSimCostForSelectedMonth = this.yearMonthCostPerSimDataCache.get(
      this.selectedBillingMonth.yearMonth,
    )?.highestSimCost;
    return highestSimCostForSelectedMonth ? `calc(${simCost} / ${highestSimCostForSelectedMonth} * 100%)` : '';
  }

  private updateData(yearMonth: string) {
    const cachedCostPerSimData = this.yearMonthCostPerSimDataCache.get(yearMonth);
    // @ts-expect-error (legacy code incremental fix)
    this.tableDataProvider.setData(cachedCostPerSimData.costPerSim);
    // @ts-expect-error (legacy code incremental fix)
    this.selectedUpdatedTime = cachedCostPerSimData.updatedTimeLabel;
  }
}
