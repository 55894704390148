<div class="ds-group">
  <header>{{'paymentStatementContact.header' | translate}}</header>
  <div class="ds-rows">
    <div class="ds-notice --warning x-payer-information-error" *ngIf="payerInformationErrorMessage">
      {{ payerInformationErrorMessage }}
    </div>
    <app-alerts [alertsManager]="alertManager"></app-alerts>
    <p>{{'paymentStatementContact.info' | translate}}</p>
    <label>
      <span class="--label">{{'paymentStatementContact.subHeader' | translate}}</span>
    </label>
    <dl class="ds-details x-payer-information">
      <dt>{{ 'payment_statements.payer_information.company_name' | translate }}</dt>
      <dd>
        <div class="x-company-name">{{ payerInformation?.companyName }}</div>
      </dd>

      <dt>{{ 'payment_statements.payer_information.department' | translate }}</dt>
      <dd>
        <div class="x-department">{{ payerInformation?.department }}</div>
      </dd>

      <dt>{{ 'payment_statements.payer_information.full_name' | translate }}</dt>
      <dd>
        <div class="x-full-name">{{ payerInformation?.fullName }}</div>
      </dd>
    </dl>
    <button type="button" class="ds-button --icon-edit x-edit-payer-information" (click)="showEditPayerInformationModal()">
      <span>{{'paymentStatementContact.buttonLabel' | translate}}</span>
    </button>
  </div>
</div>
