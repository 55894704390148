<app-user-notifications />
<main class="ds-app__main-container x-billing-page-root">
  <section class="ds-app__main-content">
    <ng-container *appNoFeature="'paymentSettings'">
      <div class="ds-rows --indent">
        <ng-container *ngTemplateOutlet="billings"></ng-container>
      </div>
    </ng-container>
  
    <ui-tabs [content]="tabsContent" *appFeature="'paymentSettings'">
  
      <!-- Billing Dashboard -->
      <ui-tab [content]="tabs[getTabIndex('BILLING_DASHBOARD')]">
        <ng-template ui-tab-content>
          <app-billing-dashboard></app-billing-dashboard>
        </ng-template>
      </ui-tab>
      
      <!-- Billing & Payment History -->
      <ui-tab [content]="tabs[getTabIndex('PAYMENT_HISTORY')]">
        <ng-template ui-tab-content>
          <ng-container *ngTemplateOutlet="billings"></ng-container>
        </ng-template>
      </ui-tab>
  
      <!-- Payment Settings -->
      <ui-tab [content]="tabs[getTabIndex('PAYMENT_SETTINGS')]">
        <ng-template ui-tab-content>
          <div class="ds-rows">
            <app-payment-method-viewer></app-payment-method-viewer>
            <app-payment-statement-contact *ngIf="allowPaymentStatements()"></app-payment-statement-contact>
          </div>
        </ng-template>
      </ui-tab>
  
      <!-- Notification & Alerts -->
      <ui-tab [content]="tabs[getTabIndex('BILLING_ALERT')]" *appFeature="'eventHandlerMonthlyChargeRule'">
        <ng-template ui-tab-content>
          <app-billing-alert></app-billing-alert>
        </ng-template>
      </ui-tab>
  
    </ui-tabs>
  </section>
  
  <ng-template #billings>
    <div class="ds-rows">
      <div appLatestBilling></div>
      <div class="ds-notice --icon-download">
        <span [innerHtml]="'serviceUsageHistoryPage.relocationWarning' | translate"></span>
      </div>
      <div appPaymentHistoryTable></div>
  
      <div *ngIf="isEuCustomer()">
        <div class="ds-text --small">
          {{ 'payments.eu_paper_invoice_option_notice' | translate }}
        </div>
      </div>
    </div>
  </ng-template>
</main>
