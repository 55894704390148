import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { BillingType } from '@soracom/shared/soracom-platform';
import { LegacyBaseSoracomApiService, SoracomApiParams } from '@user-console/legacy-soracom-api-client';
export interface Invoice {
  invoiceId: string;
  // YYYYMMDD
  dueDate: string;
  // YYYYMMDD
  invoiceDate: string;
  invoiceInfo: string;
  invoiceNumber?: string;
  // paid,
  status: string;
  existDetailedPDF: boolean;

  billingType: BillingType;
  chargedYearMonths?: string[];
  orderId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  constructor(private soracomApi: LegacyBaseSoracomApiService) {}

  list(): Promise<Invoice[]> {
    const apiParams: SoracomApiParams = {
      path: '/v1/invoices',
    };

    return this.soracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      return response.data.invoiceList;
    });
  }

  export(id: string, async: boolean, detailedPdf: boolean) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/invoices/${id}/export`,
      contentType: 'application/json',
      body: {},
    };
    if (detailedPdf) {
      apiParams.query = { detailed_pdf: 'true' };
    }
    if (async) {
      apiParams.query = { export_mode: 'async' };
    }

    return this.soracomApi.callApiWithToken(apiParams);
  }
}
