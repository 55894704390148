import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { PayerInformation } from '../../../../../app/shared/core/payer_information';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { PaymentStatementService } from '../../service/payment-statement.service';
import { EditPayerInfoComponent, EditPayerInfoOutput } from '../edit-payer-info/edit-payer-info.component';

@Component({
  selector: 'app-payment-statement-contact',
  templateUrl: './payment-statement-contact.component.html',
})
export class PaymentStatementContactComponent implements OnInit {
  alertManager = new AlertsManager();

  isLoading = false;
  // @ts-expect-error (legacy code incremental fix)
  payerInformationErrorMessage: string = null;
  payerInformation: PayerInformation | undefined;

  constructor(private paymentStatementsService: PaymentStatementService, private uiDsModalService: UiDsModalService) {}

  ngOnInit(): void {
    this.fetchPayerInformation();
  }

  async fetchPayerInformation() {
    // @ts-expect-error (legacy code incremental fix)
    this.payerInformationErrorMessage = null;

    try {
      this.payerInformation = await this.paymentStatementsService.fetchPayerInformation();
    } catch (error: LegacyAny) {
      // Ignore error if paymentInformation does not exist
      if (error.data && error.data.code === 'PAY0023') {
        this.payerInformationErrorMessage = error.data.message;
      } else {
        this.alertManager.add(Alert.fromApiError(error));
      }
    }
  }

  showEditPayerInformationModal = () => {
    this.uiDsModalService
      .openAndWaitForResult(EditPayerInfoComponent, {
        title: 'payment_statements.payer_information.header',
        data: this.payerInformation,
      })
      .then((data: EditPayerInfoOutput | null) => {
        if (data?.wasSaved) {
          this.fetchPayerInformation();
        }
      });
  };
}
