import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  generateStInMemoryServiceProviders,
  StInMemoryTableDataProviderService,
} from '@soracom/shared-ng/ui-super-table';
import { useResolveApiErrorReason } from '@soracom/shared-ng/util-common';
import { SoracomBillingServiceIconClass, SoracomBillingServiceId } from '@soracom/shared/soracom-platform';
import { ApiException } from '@soracom/shared/soracom-api-typescript-client';
import { BillingsService } from '../../../../../../app/shared/billings/billings.service';
import {
  BillingDashboardDataService,
  MonthServiceBillingPanelData,
  ServiceDetailedBillingData,
} from '../../billing-dashboard-data.service';
import { LegacyOptional } from '@soracom/shared/core';

const serviceBillingMaxRows = 8;

@Component({
  selector: 'div[serviceBillingPanel]',
  templateUrl: `service-billing-panel.component.html`,
  host: {
    class: 'ds-card --panel --indent-small ds-span --4 x-billing-dashboard-service-billing-panel',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StInMemoryTableDataProviderService<ServiceDetailedBillingData>,
    ...generateStInMemoryServiceProviders(StInMemoryTableDataProviderService<ServiceDetailedBillingData>, {
      pagination: {
        initialValue: serviceBillingMaxRows,
      },
    }),
  ],
})
export class ServiceBillingPanelComponent implements OnChanges {
  private resolveApiErrorReason = useResolveApiErrorReason();
  @Input() serviceId: LegacyOptional<SoracomBillingServiceId>;
  @Input() yearMonth: LegacyOptional<string>;
  @Input() error?: ApiException<any>;
  serviceBillingData: LegacyOptional<MonthServiceBillingPanelData>;
  serviceIconClass = '';
  maxRows = serviceBillingMaxRows;

  @HostBinding('class.--loading') loading = false;

  @HostBinding('attr.data-ds-message') errorMsg: LegacyOptional<string>;

  @HostBinding('class') get testClass() {
    return `x-${this.serviceId}`;
  }

  constructor(
    private billingService: BillingsService,
    private billingDashboardService: BillingDashboardDataService,
    private translateService: TranslateService,
    private cdf: ChangeDetectorRef,
    private stTableDataProvider: StInMemoryTableDataProviderService<ServiceDetailedBillingData>,
  ) {}

  ngOnChanges() {
    if (this.error) {
      this.handleError(this.error);
      return;
    }
    if (this.serviceId && this.yearMonth) {
      this.fetchServiceBillingData();
    }
  }

  private fetchServiceBillingData() {
    this.errorMsg = undefined;
    this.loading = true;
    this.billingDashboardService
      // @ts-expect-error
      .getMonthServiceBilling(this.yearMonth, this.serviceId)
      .then((response: LegacyAny) => {
        this.serviceBillingData = response;
        this.serviceIconClass = SoracomBillingServiceIconClass(response?.serviceId);
        this.stTableDataProvider.setData(this.serviceBillingData?.serviceCostBreakdown ?? []);
      })
      .catch((err: LegacyAny) => {
        this.handleError(err);
      })
      .finally(() => {
        this.loading = false;
        this.cdf.markForCheck();
      });
  }

  get dateRangeWarning() {
    return this.serviceBillingData
      ? this.billingService.getDateRangeWarningMessage(this.serviceBillingData.month, this.serviceBillingData.finalDay)
      : '';
  }

  private handleError(err: any) {
    this.errorMsg = this.resolveApiErrorReason(err);
  }
}
