<p class="ds-tag --pinned">{{'billingDashboard.costPerSim.title' | translate}}</p>
<div class="ds-text --xxsmall">{{selectedUpdatedTime}}</div>
<div class="ds-cols --gap-xsmall --middle">
    <div class="ds-text" [innerHtml]="'billingDashboard.costPerSim.description' | translate"></div>
    <st-paging-status class="ds-span --end --small"></st-paging-status>
    <st-items-per-page-selector selectClass="ds-select --small"></st-items-per-page-selector>
    <button class="--small" stPrevButton></button>
    <button class="--small" stNextButton></button>
</div>
<table class="ds-datatable --plain --compact --striped" stTable stLoading sticky>
    <ng-container stColumnDef="name">
        <th *stHeaderCell class="--40-w-pc">{{ 'billingDashboard.costPerSim.nameColumn' | translate | uppercase }}</th>
        <td *stCell="let item"><div class="ds-text --small">{{ item?.name || '—'}}</div></td>
    </ng-container>

    <ng-container stColumnDef="groupId">
        <th *stHeaderCell class="--30-w-pc">{{'generic.group' | translate | uppercase}}</th>
        <td *stCell="let item"><div class="ds-text --small">{{ item.groupId | groupName }}</div></td>
    </ng-container>

    <ng-container stColumnDef="simId">
        <th *stHeaderCell class="--min">{{ 'billingDashboard.costPerSim.simIdColumn' | translate | uppercase }}</th>
        <td *stCell="let item"><div class="ds-text --small --no-wrap">{{ item.simId }}</div></td>
    </ng-container>

    <ng-container stColumnDef="scale">
        <th *stHeaderCell>{{ 'billingDashboard.costPerSim.costColumn' | translate | uppercase }}</th>
        <td *stCell="let item">
            <div class="--ds-scale" [style.--ds-scale-pc]="simScaleStyle(item.amount)"></div>
        </td>
    </ng-container>

    <ng-container stColumnDef="amount">
        <th *stHeaderCell class="--right"></th>
        <td *stCell="let item"><div class="ds-text --small --no-wrap --right">{{ item.amount | priceAmount: currencyUnit }}</div></td>
    </ng-container>

    <tr *stRowDef="let item" [stRow]="item"></tr>

    <tr *stNoDataRowDef>
      <td colspan="100" class="fullwidth-message">
       <div>{{'billingDashboard.costPerSim.noDataMessage' | translate}}</div>
      </td>
    </tr>
</table>
<footer>
    <p class="ds-text --xxsmall --icon-info" [innerHtml]="'billingDashboard.costPerSim.priceDifferenceWarning' | translate"></p>
</footer>
