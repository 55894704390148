import { LegacyAny } from '@soracom/shared/core';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OperatorConfigurationService } from '@soracom/shared-ng/operator-configuration';
import { useResolveApiErrorReason } from '@soracom/shared-ng/util-common';
import { MonthlyBill } from '@soracom/shared/soracom-api-typescript-client';
import { BillingsService } from 'apps/user-console/app/shared/billings/billings.service';
import { BillingDashboardDataService, MonthRecentBillingPanelData } from '../../billing-dashboard-data.service';

@Component({
  selector: 'a[recentBillingPanel], div[recentBillingPanel]',
  templateUrl: `recent-billing-panel.component.html`,
  host: {
    class: 'ds-card --panel --indent-small --tiled ds-span --6 x-billing-dashboard-recent-billing-panel',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentBillingPanelComponent implements OnInit {
  private resolveApiErrorReason = useResolveApiErrorReason();
  billingMonths: MonthRecentBillingPanelData[] = [];
  currencyUnit: string | undefined;
  // @ts-expect-error (legacy code incremental fix)
  updatedTimeLabel: string;
  // @ts-expect-error (legacy code incremental fix)
  private _selectedYearMonth: string;
  private _selectedBillingMonth: MonthRecentBillingPanelData | undefined;
  get selectedBillingMonth(): MonthRecentBillingPanelData {
    // @ts-expect-error (legacy code incremental fix)
    return this._selectedBillingMonth;
  }
  @Input() set selectedYearMonth(yearMonth: string) {
    this._selectedYearMonth = yearMonth;
    if (this.billingMonths) {
      this._selectedBillingMonth = this.billingMonths.find((monthData) => monthData.yearMonth === yearMonth);
    }
  }
  @Output() onMonthClick = new EventEmitter<string>();

  @HostBinding('class.--loading') loading = true;

  @HostBinding('attr.data-ds-message') errorMsg: LegacyAny;

  constructor(
    private translateService: TranslateService,
    private billingDashboardService: BillingDashboardDataService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private billingsService: BillingsService,
    private operatorConfigurationService: OperatorConfigurationService,
  ) {}

  ngOnInit() {
    this.billingDashboardService
      .getRecentBillingPanelData()
      .then((billingMonthData) => {
        this.billingMonths = billingMonthData;
        this._selectedBillingMonth = this.billingMonths.find(
          (monthData) => monthData.yearMonth === this._selectedYearMonth,
        );
      })
      .catch((err: LegacyAny) => {
        this.errorMsg = this.resolveApiErrorReason(err);
      })
      .finally(() => {
        this.loading = false;
        this.changeDetector.markForCheck();
      });
    this.billingDashboardService.getCurrencyUnit().then((currencyUnit) => {
      this.currencyUnit = currencyUnit;
      this.changeDetector.markForCheck();
    });
    this.getUpdatedTimeLabel();
  }

  isSelectedBillingMonth(billingPeriod: MonthRecentBillingPanelData) {
    return (
      this.selectedBillingMonth.month === billingPeriod.month && this.selectedBillingMonth.year === billingPeriod.year
    );
  }

  dateRangeInfo(billingMonth: MonthRecentBillingPanelData): string {
    if (billingMonth.finalDay > 1) {
      return this.translateService.instant('billingDashboard.recentBilling.dateRangeInfo', {
        endDay: billingMonth.finalDay,
        monthName: billingMonth.monthName,
      });
    } else {
      return this.translateService.instant('billingDashboard.recentBilling.dateRangeInfoSingleDay', {
        monthName: billingMonth.monthName,
      });
    }
  }

  handleMonthClick(billingMonth: MonthRecentBillingPanelData) {
    this.onMonthClick.emit(billingMonth.yearMonth);
  }

  isCurrentMonth(yearMonth: string) {
    return yearMonth === this.billingMonths[0].yearMonth;
  }

  monthScaleStyle(billingMonth: MonthRecentBillingPanelData): string {
    return `calc(${billingMonth.finalDay} / ${billingMonth.totalDays} * 100%)`;
  }

  currencySymbolTranslated(src: MonthlyBill) {
    return this.billingsService.currencySymbolTranslated(src);
  }

  private getUpdatedTimeLabel() {
    this.billingDashboardService.getLatestBillTimeStampLabel().then((label) => {
      this.updatedTimeLabel = label;
      this.changeDetector.markForCheck();
    });
  }
}
