import { LegacyAny } from '@soracom/shared/core';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { useResolveApiErrorReason } from '@soracom/shared-ng/util-common';
import { CurrencyCode } from '@soracom/shared/regulatory';
import { getDsBarChartHorizontalConfig } from '@soracom/shared-ng/ng-charts';
import { Chart } from 'chart.js';
import {
  BillingDashboardDataService,
  BillingDashboardDateData,
  ServiceTotalCostData,
} from '../../billing-dashboard-data.service';

@Component({
  selector: 'a[billingByServicePanel], div[billingByServicePanel]',
  templateUrl: `billing-by-service-panel.component.html`,
  host: {
    class: 'ds-card --panel --indent-small ds-span --6 x-billing-dashboard-billing-by-service-panel',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingByServicePanelComponent implements OnInit, OnDestroy {
  resolveApiErrorReason = useResolveApiErrorReason();
  // @ts-expect-error (legacy code incremental fix)
  private selectedBillingMonth: BillingDashboardDateData;
  @Input() set selectedYearMonth(selectedYearMonth: string) {
    if (selectedYearMonth) {
      this.selectedYearMonthChanged(selectedYearMonth);
    }
  }

  @HostBinding('class.--loading') get loading() {
    return (!this.selectedBillingMonth || this.loadingChart) && !this.errorMsg;
  }
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('billingByServiceChartCanvas') canvasEl: ElementRef;
  // @ts-expect-error (legacy code incremental fix)
  private chartInstance: Chart;
  // @ts-expect-error (legacy code incremental fix)
  private chartConfig: MonthBillingByServiceChartConfig;
  /**Cached view data for a selected month to avoid repeated requests for an already selected month's data */
  private viewDataMap: { [yearMonth: string]: MonthBillingByServiceViewData } = {};
  // @ts-expect-error (legacy code incremental fix)
  private currencyUnit: CurrencyCode;

  constructor(
    private billingDashboardService: BillingDashboardDataService,
    private cdf: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {}

  @HostBinding('attr.data-ds-message') errorMsg: LegacyAny;

  ngOnInit(): void {
    // @ts-expect-error (legacy code incremental fix)
    this.billingDashboardService.getCurrencyUnit().then((currencyUnit: CurrencyCode) => {
      this.currencyUnit = currencyUnit;
      if (this.chartConfig) this.updateChart();
    });
  }

  ngOnDestroy(): void {
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  }

  private selectedYearMonthChanged(selectedYearMonth: string) {
    this.errorMsg = null;
    this.chartConfig = this.viewDataMap[selectedYearMonth]?.chartConfig;
    this.selectedBillingMonth = this.billingDashboardService.getDateDataForBillingMonth(selectedYearMonth);
    if (this.chartConfig) {
      this.updateChart();
    } else {
      this.loadBillingByServiceDataForMonth(this.selectedBillingMonth);
    }
  }

  private loadBillingByServiceDataForMonth(billingMonth: BillingDashboardDateData) {
    this.billingDashboardService
      .getMonthBillingByService(this.selectedBillingMonth.yearMonth)
      .then((billingByServiceMonthData) => {
        if (!this.viewDataMap[billingMonth.yearMonth]) {
          this.viewDataMap[billingMonth.yearMonth] = {
            chartConfig: this.createChartConfig(billingByServiceMonthData.serviceCostBreakdown),
            updatedTimeLabel: billingByServiceMonthData.updatedTimeLabel,
          };
        }
        //while waiting for API response, the selected month may have changed.  So only update the chart if the returned data is for the selected month
        if (this.selectedBillingMonth.yearMonth === billingMonth.yearMonth) {
          this.chartConfig = this.viewDataMap[billingMonth.yearMonth].chartConfig;
          this.updateChart();
        }
      })
      .catch((err: LegacyAny) => {
        this.errorMsg = this.resolveApiErrorReason(err);
      })
      .finally(() => {
        this.cdf.markForCheck();
      });
  }

  private async initChart() {
    const config = await getDsBarChartHorizontalConfig();
    config.data.labels = [];
    config.data.datasets = [];
    // @ts-expect-error (legacy code incremental fix)
    config.options.plugins.datalabels.formatter = (value, context) => {
      return this.determineChartLabelForAmount(value);
    };
    // @ts-expect-error (legacy code incremental fix)
    config.options.plugins.tooltip = {
      enabled: false,
    };
    // @ts-expect-error (legacy code incremental fix)
    config.options.scales.y.ticks.autoSkip = false;

    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
    this.chartInstance = new Chart(this.canvasEl.nativeElement.getContext('2d'), config);
  }

  private async updateChart() {
    if (!this.chartInstance) {
      await this.initChart();
    }
    this.chartInstance.data.labels = this.chartConfig.labels;
    this.chartInstance.data.datasets = this.chartConfig.datasets;
    this.chartInstance.update();
  }

  private createChartConfig(serviceCostData: ServiceTotalCostData[]): MonthBillingByServiceChartConfig {
    return {
      labels: serviceCostData.map((serviceCostData) => serviceCostData.service),
      datasets: [
        {
          label: 'Amount',
          data: serviceCostData.map((serviceCostData) => serviceCostData.amount),
        },
      ],
    };
  }

  private get loadingChart() {
    return !this.chartConfig;
  }

  private determineChartLabelForAmount(amount: number) {
    return this.billingDashboardService.determineLabelForPriceAmount(amount, this.currencyUnit);
  }

  public get updatedTimeLabel() {
    return this.viewDataMap[this.selectedBillingMonth.yearMonth]?.updatedTimeLabel;
  }
}

export interface MonthBillingByServiceViewData {
  updatedTimeLabel: string;
  chartConfig: MonthBillingByServiceChartConfig;
}

export interface MonthBillingByServiceChartConfig {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
  }[];
}
