<div dsDialogContent>
  <app-alerts [alertsManager]="alertManager"></app-alerts>

  <div class="ds-rows">
    <div class="ds-field">
      <ui-input [content]="companyName"></ui-input>
    </div>

    <div class="ds-field">
      <ui-input [content]="department"></ui-input>
    </div>

    <div class="ds-field">
      <ui-input [content]="fullName"></ui-input>
    </div>
  </div>
</div>
<footer dsDialogFooter>
  <ui-button-bar [content]="footerButtonBar"></ui-button-bar>
</footer>
