import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { CoverageType } from '@foundation/coverage-type';
import { TranslateService } from '@ngx-translate/core';
import { OperatorConfigurationService } from '@soracom/shared-ng/operator-configuration';
import { Alert, AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { CoverageTypeService, FeatureVisibilityService, LoginUserDataService } from '@soracom/shared/data-access-auth';
import { CurrencyCode } from '@soracom/shared/regulatory';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import {
  EventHandler,
  ExecutionDateTimeConst,
  createMonthlyChargeAlert,
  isMonthlyChargeAlert,
  isMonthlyChargeRule,
  isSendMailToOperatorAction,
} from '../../../../app/shared/event_handlers/event_handler';

const translateNamespace = 'prefixed_event_handlers.handler_text.SendMailToOperatorAction.BillingAlert';
const translationKeys = [`${translateNamespace}.Subject`, `${translateNamespace}.Message`];

@Component({
  selector: 'app-billing-alert',
  templateUrl: './billing-alert.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class BillingAlertComponent implements OnInit {
  alertsManager: AlertsManager = new AlertsManager();
  // @ts-expect-error (legacy code incremental fix)
  threshold: number;
  // @ts-expect-error (legacy code incremental fix)
  title: string;
  // @ts-expect-error (legacy code incremental fix)
  message: string;
  enabled = false;
  defaultThreshold = 10;
  coverageType: CoverageType = 'g';
  // @ts-expect-error (legacy code incremental fix)
  currencyCode: CurrencyCode;
  showDetail = false;

  // @ts-expect-error (legacy code incremental fix)
  private eventHandler: EventHandler;

  constructor(
    private coverageTypeService: CoverageTypeService,
    private featureVisibilityService: FeatureVisibilityService,
    private loginUserDataService: LoginUserDataService,
    private soracomApi: SoracomApiService,
    private translateService: TranslateService,
    private operatorConfigurationService: OperatorConfigurationService
  ) {}

  ngOnInit(): void {
    if (this.featureVisibilityService.isEnabled('eventHandlerMonthlyChargeRule')) {
      // @ts-expect-error (legacy code incremental fix)
      this.coverageType = this.coverageTypeService.getCoverageType();

      const isGlobal = this.coverageTypeService.isGlobal();
      this.defaultThreshold = isGlobal ? 10 : 1000; // Set 10 USD/EUR, or 1,000 JPY

      const operatorCurrency = this.operatorConfigurationService.operatorCurrency(this.coverageType);
      const defaultCurrency: CurrencyCode = isGlobal ? 'USD' : 'JPY';
      this.currencyCode = operatorCurrency ?? defaultCurrency;

      this.fetchEventHandler();
    }
  }

  save() {
    const actionConfig = this.eventHandler.actionConfigList[0];
    this.eventHandler.status = this.enabled ? 'active' : 'inactive';
    if (isMonthlyChargeRule(this.eventHandler.ruleConfig)) {
      if (isSendMailToOperatorAction(actionConfig)) {
        actionConfig.properties.executionDateTimeConst = ExecutionDateTimeConst.IMMEDIATELY;
        if (this.showDetail || !this.eventHandler.handlerId) {
          actionConfig.properties.title = this.title;
          actionConfig.properties.message = this.message;
        }
        this.eventHandler.ruleConfig.properties.limitTotalAmount = this.threshold;
      }

      if (this.eventHandler.handlerId) {
        return this.soracomApi
          .updateEventHandler(this.eventHandler)
          .then(() => {
            this.alertsManager.add(Alert.success('billing_alert_component.success'));
          })
          .catch((error: LegacyAny) => {
            this.alertsManager.add(Alert.fromApiError(error));
          });
      } else {
        return this.soracomApi
          .createEventHandler(this.eventHandler)
          .then(() => {
            this.alertsManager.add(Alert.success('billing_alert_component.success'));
            this.fetchEventHandler();
          })
          .catch((error: LegacyAny) => {
            this.alertsManager.add(Alert.fromApiError(error));
          });
      }
    }
  }

  restoreDefault() {
    this.setDefaultEmailContent();
  }

  delete() {
    this.soracomApi
      .destroyEventHandler(this.eventHandler)
      .then(() => {
        this.alertsManager.add(Alert.success('billing_alert_component.success'));
        this.fetchEventHandler();
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
      });
  }

  private setDefaultEmailContent() {
    const key = `currency.${this.currencyCode}.postfixSymbol`;
    const currency = this.translateService.instant(key);
    this.translateService
      .get(translationKeys, { currency })
      .toPromise()
      .then((translations) => {
        this.title = translations[translationKeys[0]];
        this.message = translations[translationKeys[1]];
      });
  }

  private fetchEventHandler() {
    // @ts-expect-error (legacy code incremental fix)
    this.eventHandler = null;
    this.soracomApi
      .getEventHandlers('operator')
      .then((res: LegacyAny) => {
        res.data.forEach((element: LegacyAny) => {
          const eventHandler = new EventHandler(element);
          if (isMonthlyChargeAlert(eventHandler)) {
            this.eventHandler = eventHandler;
            this.enabled = eventHandler.status === 'active';
            const ruleConfig = this.eventHandler.ruleConfig;
            if (isMonthlyChargeRule(ruleConfig)) {
              this.threshold = ruleConfig.properties.limitTotalAmount;
              const actionConfig = this.eventHandler.actionConfigList[0];
              if (isSendMailToOperatorAction(actionConfig)) {
                this.title = actionConfig.properties.title;
                this.message = actionConfig.properties.message;
              }
            }
          }
        });
        if (this.eventHandler === null || this.eventHandler === undefined) {
          // @ts-expect-error (legacy code incremental fix)
          this.eventHandler = createMonthlyChargeAlert(this.loginUserDataService.getLoginUser()?.operatorId);
          this.enabled = this.eventHandler.status === 'active';
          this.threshold = this.defaultThreshold;
          this.setDefaultEmailContent();
        }
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.fromApiError(error));
      });
  }
}
