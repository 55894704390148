import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { BillingType } from '@soracom/shared/soracom-platform';
import { PayerInformation } from 'apps/user-console/app/shared/core/payer_information';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { PaymentHistoryEntry, PaymentHistoryService } from '../service/payment-history.service';
import { PaymentStatementService } from '../service/payment-statement.service';

export type PaymentStatementTypeFilter = 'ALL' | BillingType;

@Component({
  templateUrl: './payment-history-table.component.html',
  selector: 'div[appPaymentHistoryTable]',
  host: {
    class: 'ds-group --no-indent',
  },
})
export class PaymentHistoryTableComponent implements OnInit {
  isLoading = false;
  // @ts-expect-error (legacy code incremental fix)
  private paymentHistory: PaymentHistoryEntry[];
  // @ts-expect-error (legacy code incremental fix)
  filteredPaymentHistory: PaymentHistoryEntry[];
  // @ts-expect-error (legacy code incremental fix)
  payerInformation: PayerInformation;
  alertManager = new AlertsManager();
  paymentHistoryTypeFilter: PaymentStatementTypeFilter = 'ALL';

  constructor(
    private paymentHistoryService: PaymentHistoryService,
    private featureService: FeatureVisibilityService,
    private paymentStatementService: PaymentStatementService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.fetchPaymentHistory();
  }

  async fetchPaymentHistory() {
    this.isLoading = true;
    try {
      this.paymentHistory = await this.paymentHistoryService.getPaymentHistoryData();
      this.updateTable();
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  }

  updateTable() {
    this.filteredPaymentHistory = this.paymentHistory.filter((paymentStatement) => {
      return this.paymentHistoryTypeFilter === 'ALL' || this.paymentHistoryTypeFilter === paymentStatement.type;
    });
  }

  exportPaymentStatement(paymentHistoryEntry: PaymentHistoryEntry) {
    this.paymentHistoryService.exportPaymentStatement(paymentHistoryEntry);
  }

  isPaymentStatementDownloading(paymentHistoryEntry: PaymentHistoryEntry) {
    return this.paymentHistoryService.isPaymentStatementDownloading(paymentHistoryEntry);
  }

  exportInvoice(paymentHistoryEntry: PaymentHistoryEntry) {
    this.paymentHistoryService.exportInvoice(paymentHistoryEntry);
  }

  isInvoiceDownloading(paymentHistoryEntry: PaymentHistoryEntry) {
    return this.paymentHistoryService.isInvoiceDownloading(paymentHistoryEntry);
  }

  exportServiceChargeCSV(paymentHistoryEntry: PaymentHistoryEntry) {
    this.paymentHistoryService.exportMonthlyBillCsv(paymentHistoryEntry);
  }

  isServiceChargeCSVDownloading(paymentHistoryEntry: PaymentHistoryEntry) {
    return this.paymentHistoryService.isBillCSVDownloading(paymentHistoryEntry);
  }

  isServiceCharge(paymentHistoryEntry: PaymentHistoryEntry) {
    return paymentHistoryEntry.type === 'MONTHLY_CHARGE';
  }

  isOrder(paymentHistoryEntry: PaymentHistoryEntry) {
    return paymentHistoryEntry.type === 'ORDER';
  }

  hasError(paymentHistoryEntry: PaymentHistoryEntry) {
    return paymentHistoryEntry.status === 'fail';
  }

  invoiceAllowed() {
    return this.featureService.isEnabled('invoices');
  }

  paymentStatementAllowed() {
    return this.paymentStatementService.paymentStatementsAllowed();
  }

  isPaid(paymentHistoryEntry: PaymentHistoryEntry) {
    return paymentHistoryEntry.status === 'paid' || paymentHistoryEntry.status === 'closed';
  }

  isDeferred(paymentHistoryEntry: PaymentHistoryEntry) {
    return paymentHistoryEntry.status === 'deferred';
  }

  isEnLang() {
    return this.translateService.currentLang === 'en';
  }

  canDownloadHistoryEntryInvoice(payment: PaymentHistoryEntry) {
    return payment.hasInvoice && !this.isDeferred(payment) && this.invoiceAllowed();
  }

  canDownloadHistoryEntryPaymentStatement(payment: PaymentHistoryEntry) {
    return payment.hasPaymentStatement && !this.isDeferred(payment) && this.paymentStatementAllowed();
  }

  canDownloadHistoryEntryServiceCharge(payment: PaymentHistoryEntry) {
    return this.isServiceCharge(payment);
  }

  paymentHasDownloadOptions(payment: PaymentHistoryEntry) {
    return (
      this.canDownloadHistoryEntryInvoice(payment) ||
      this.canDownloadHistoryEntryPaymentStatement(payment) ||
      this.canDownloadHistoryEntryServiceCharge(payment)
    );
  }
}
