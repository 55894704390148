<div class="ds-group x-billing-alert-component" *appFeature="'eventHandlerMonthlyChargeRule'">
  <header>
    <div class="ds-rows --gap-xsmall">
      <h4>{{ 'billing_alert_component.title' | translate }}</h4>
      <div class="ds-text" translate="billing_alert_component.description"></div>
    </div>
  </header>
  <div class="ds-rows">
    <ng-container>
      <app-alerts [alertsManager]="alertsManager"></app-alerts>
    </ng-container>
    <div class="ds-notice --info">{{'billing_alert_component.updatedTimeWarning' | translate}}</div>

    <div class="ds-field">
      <div class="ds-switch">
        <input id="enabled" type="checkbox" [(ngModel)]="enabled" />
        <label for="enabled">{{ 'billing_alert_component.labels.enabled' | translate }}</label>
      </div>
    </div>
    <div class="ds-field">
      <label
        class="ds-label"
        for="threshold"
        [translate]="'billing_alert_component.labels.threshold_' + currencyCode"
      ></label>
      <div class="ds-input">
        <input
          id="threshold"
          type="number"
          [(ngModel)]="threshold"
          required
          [disabled]="!enabled"
          min="0"
          max="100000000"
        />
      </div>
    </div>
    <div class="ds-field">
      <div class="ds-checkbox">
        <input id="showDetail" type="checkbox" [(ngModel)]="showDetail" [disabled]="!enabled" />
        <label for="showDetail">{{ 'billing_alert_component.labels.show_detail' | translate }}</label>
      </div>
    </div>
    <div class="ds-field" *ngIf="showDetail">
      <label class="ds-label" for="title" translate="billing_alert_component.labels.title"></label>
      <div class="ds-input">
        <input id="title" type="text" [(ngModel)]="title" required [disabled]="!enabled" />
      </div>
    </div>
    <div class="ds-field" *ngIf="showDetail">
      <label class="ds-label" for="title">
        {{ 'billing_alert_component.labels.body' | translate }}
        <span style="margin-left: auto">
          <div class="ds-richtip --bottom-left">
            <div class="__button ds-text">
              {{ 'billing_alert_component.availablePlaceholders.richtipLabel' | translate }}
            </div>
            <div class="__content">
              <p class="ds-text --small">
                {{ 'billing_alert_component.availablePlaceholders.aboutPlaceholders' | translate }}
              </p>
              <table class="ds-datatable --condensed">
                <thead>
                  <tr>
                    <th class="--min" style="min-width: 250px">
                      {{ 'billing_alert_component.availablePlaceholders.column.placeholders' | translate }}
                    </th>
                    <th style="width: 150px">
                      {{ 'billing_alert_component.availablePlaceholders.column.description' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span class="ds-text --code">{{ '${operatorId}' }}</span>
                      <span (click)="$event.preventDefault(); $event.stopPropagation()">
                        <button appCopyButton="${operatorId}">
                          <span>Copy</span>
                        </button>
                      </span>
                    </td>
                    <td>
                      {{
                        'billing_alert_component.availablePlaceholders.placeholderDescription.operatorId' | translate
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="ds-text --code">{{ '${limitTotalAmount}' }}</span>
                      <span (click)="$event.preventDefault(); $event.stopPropagation()">
                        <button appCopyButton="${limitTotalAmount}">
                          <span>Copy</span>
                        </button>
                      </span>
                    </td>
                    <td>
                      {{
                        'billing_alert_component.availablePlaceholders.placeholderDescription.limitTotalAmount'
                          | translate
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="ds-text --code">{{ '${currentTotalAmount}' }}</span>
                      <span (click)="$event.preventDefault(); $event.stopPropagation()">
                        <button appCopyButton="${currentTotalAmount}">
                          <span>Copy</span>
                        </button>
                      </span>
                    </td>
                    <td>
                      {{
                        'billing_alert_component.availablePlaceholders.placeholderDescription.currentTotalAmount'
                          | translate
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </span>
      </label>
      <textarea id="title" class="ds-input" rows="8" [(ngModel)]="message" required [disabled]="!enabled"></textarea>
    </div>
    <div class="ds-field" *ngIf="showDetail">
      <button type="button" class="ds-button" (click)="restoreDefault()">
        <span>{{ 'billing_alert_component.restore_default' | translate }}</span>
      </button>
    </div>
    <div class="ds-field">
      <button type="button" class="ds-button --primary" (click)="save()">
        <span>{{ 'billing_alert_component.save' | translate }}</span>
      </button>
    </div>
  </div>
</div>
