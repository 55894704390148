<header>
    <div class="ds-rows --gap-xsmall">
        <div class="ds-cols --middle">
            <h4>
                {{ 'billings.latest.header' | translate }}
                <span *ngIf="shouldShowAmount()">{{ getYearMonthHeader() }}</span>
            </h4>
            <div class="ds-tag --small --color-celeste-lighter ds-span --end"><span><strong>{{'billings.latest.pending' | translate}}</strong>{{'billings.latest.aggregating' | translate}}</span></div>
        </div>
        <div class="ds-cols --middle --v-gap-xsmall">
            <div class="ds-text --small --icon-timer --color-blue-shade">{{'billingDashboard.updatedTimeWarning' | translate}}</div>
            <div class="ds-text --small --icon-info">{{'billings.latest.totalBillingWarning' | translate}}</div>
            <div class="ds-text --small --icon-calendar-dates">{{dateRangeWarning}}</div>
            <div class="ds-text --small --icon-time">{{getUpdatedDateTime()}}</div>
        </div>
    </div>
</header>
<div class="ds-rows">
    <app-alerts [alertsManager]="alertManager"></app-alerts>
    <h2><span>{{getAmount() | priceAmount:'':'':true}} </span><span class="latest-bill-unit">{{ currencySymbolTranslated() }}</span></h2>
    <div class="ds-cols">
        <button (click)="downloadCsv()" [disabled]="isDownloadOperationInProgress" [ngClass]="{'--loading': isDownloadOperationInProgress}" class="ds-button --icon-download x-download-latest-billing-csv"><span>{{ 'billings.download_latest_csv' | translate }}</span></button>
    </div>
</div>
<div class="ds-notice">
    <p>{{'billings.latest.usageChargeInfo' | translate}}</p>
</div>
