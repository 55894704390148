import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiException } from '@soracom/shared/soracom-api-typescript-client';
import { BillingsService } from 'apps/user-console/app/shared/billings/billings.service';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import {
  BillingDashboardDataService,
  BillingDashboardDateData,
  MonthBilledServiceLists,
} from './billing-dashboard-data.service';

@Component({
  selector: 'app-billing-dashboard',
  templateUrl: './billing-dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BillingDashboardDataService],
})
export class BillingDashboardComponent implements OnInit {
  tabs: BillingDashboardMonthTab[] = [];
  billingMonths: BillingDashboardDateData[] = [];
  // @ts-expect-error (legacy code incremental fix)
  selectedBillingMonth: BillingDashboardDateData;
  billedServicesYearMonthMap: { [key: string]: MonthBilledServiceLists } = {};
  // @ts-expect-error (legacy code incremental fix)
  selectedMonthBilledServices: MonthBilledServiceLists;
  alertsManager = new AlertsManager();
  // @ts-expect-error (legacy code incremental fix)
  serviceListError: ApiException<any>;

  constructor(
    private translateService: TranslateService,
    private coverageTypeService: CoverageTypeService,
    private billingDashboardService: BillingDashboardDataService,
    private billingsService: BillingsService,
    private cdf: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initBillingMonthData();
    this.initTabs();
  }

  private initBillingMonthData() {
    this.billingMonths = this.billingDashboardService.billingMonthList;
    this.updateSelectedMonth(this.billingMonths[0].yearMonth);
  }

  private initTabs() {
    this.billingMonths.forEach((dateData, index) => {
      this.tabs.push({
        label: index === 0 ? this.labelForCurrentMonth(dateData) : dateData.monthName,
        onClick: () => this.updateSelectedMonth(dateData.yearMonth),
        id: dateData.yearMonth,
      });
    });
  }

  public updateSelectedMonth(yearMonth: string) {
    // @ts-expect-error (legacy code incremental fix)
    this.selectedBillingMonth = this.billingMonths.find((dateData) => dateData.yearMonth === yearMonth);
    this.updateBilledServicesList();
  }

  private updateBilledServicesList() {
    // @ts-expect-error (legacy code incremental fix)
    this.serviceListError = null;
    this.selectedMonthBilledServices = {
      mainServices: [],
      otherServices: [],
    };
    if (this.billedServicesYearMonthMap[this.selectedBillingMonth.yearMonth]) {
      this.selectedMonthBilledServices = this.billedServicesYearMonthMap[this.selectedBillingMonth.yearMonth];
    } else {
      this.billingDashboardService
        .getMonthBilledServiceList(this.selectedBillingMonth.yearMonth)
        .then((billedServices) => {
          this.billedServicesYearMonthMap[this.selectedBillingMonth.yearMonth] = billedServices;
          this.selectedMonthBilledServices = billedServices;
        })
        .catch((err: LegacyAny) => {
          this.serviceListError = err;
        })
        .finally(() => {
          this.cdf.markForCheck();
        });
    }
  }

  private labelForCurrentMonth(dateData: BillingDashboardDateData) {
    return this.translateService.instant('date.currentMonthLabel', {
      monthName: dateData.monthName,
    });
  }

  public get dateRangeWarning() {
    return this.billingsService.getDateRangeWarningMessage(
      this.selectedBillingMonth.month,
      this.selectedBillingMonth.finalDay,
    );
  }

  public isSelectedTab(tab: BillingDashboardMonthTab) {
    return this.selectedBillingMonth.yearMonth === tab.id;
  }

  public get currentMonthIsSelected() {
    return this.selectedBillingMonth.yearMonth === this.billingMonths[0].yearMonth;
  }
}

interface BillingDashboardMonthTab {
  label: string;
  id: string; //this will be the yearMonth
  onClick: () => void;
}
