import { BaseModel } from '@soracom/shared/soracom-platform';

export interface PayerInformation {
  companyName: string;
  department: string;
  fullName: string;
}

export class PayerInformation extends BaseModel implements PayerInformation {
  constructor(params?: any) {
    super();
    if (params) {
      this.companyName = params.companyName;
      this.department = params.department;
      this.fullName = params.fullName;
    }
  }
}
