<ul class="ds-tabs x-billing-dashboard" data-testid="billing-dashboard-tabs">  
  <li class="x-billing-dashboard-month-tab" data-testid="billing-dashboard-month-tab" *ngFor="let tab of tabs" (click)="tab.onClick()" [ngClass]="{'--selected': isSelectedTab(tab)}">
    <span>{{tab.label}}</span>
  </li>
</ul>
<div class="ds-rows --indent-small" data-testid="billing-dashboard-container">
  <h3 class="ds-heading ds-span --12">{{dateRangeWarning}}</h3>
  <div class="ds-cols --middle --v-gap-xsmall">
    <div *ngIf="currentMonthIsSelected" class="ds-text --small --icon-timer --color-blue-shade">{{'billingDashboard.updatedTimeWarning' | translate}}</div>
    <div appCoverageBillingWarning></div>
    <div class="ds-text --small --icon-info">{{'billingDashboard.totalBillingWarning' | translate}}</div>
  </div>
  <div class="ds-cols --stretch --4">
    <div recentBillingPanel [selectedYearMonth]="selectedBillingMonth.yearMonth" (onMonthClick)="updateSelectedMonth($event)"></div>
    <div billingByServicePanel [selectedYearMonth]="selectedBillingMonth.yearMonth"></div>
    <div costPerSimPanel [selectedYearMonth]="selectedBillingMonth.yearMonth"></div>
    <div *ngFor="let serviceId of selectedMonthBilledServices.mainServices" serviceBillingPanel [yearMonth]="selectedBillingMonth.yearMonth" [serviceId]="serviceId"></div>
    <div *ngFor="let serviceId of selectedMonthBilledServices.otherServices" serviceBillingPanel [yearMonth]="selectedBillingMonth.yearMonth" [serviceId]="serviceId"></div>
    <div *ngIf="serviceListError" serviceBillingPanel [yearMonth]="selectedBillingMonth.yearMonth" [error]="serviceListError"></div>
  </div>
  <div class="ds-notice --info" [innerHtml]="'billingDashboard.serviceBilling.info' | translate"></div>
</div>
