<header class="ds-cols --indent-medium">{{'paymentHistoryTable.header' | translate}}</header>
<app-alerts [alertsManager]="alertManager"></app-alerts>

<div class="ds-controls">
    <div class="ds-button-bar">
        <p class="--label --xsmall">{{'paymentHistoryTable.filter.label' | translate}}</p>
        <label class="ds-tag --color-ink-light">
            <input type="radio" name="paymentHistoryTypeFilter" value="ALL" [(ngModel)]="paymentHistoryTypeFilter" (change)="updateTable()" [disabled]="isLoading">
            <span>{{'paymentHistoryTable.filter.all' | translate}}</span>
        </label>
        <label class="ds-tag --color-ink-lighter">
            <input type="radio" name="paymentHistoryTypeFilter" value="MONTHLY_CHARGE" [(ngModel)]="paymentHistoryTypeFilter" (change)="updateTable()" [disabled]="isLoading">
            <span>{{'paymentHistoryTable.filter.usageCharges' | translate}}</span>
        </label>
        <label class="ds-tag --color-ink-lighter">
            <input type="radio" name="paymentHistoryTypeFilter" value="ORDER" [(ngModel)]="paymentHistoryTypeFilter" (change)="updateTable()">
            <span>{{'paymentHistoryTable.filter.orders' | translate}}</span>
        </label>
    </div>
</div>
<div class="--responsive">
<table class="ds-datatable --plain --striped --small" [ngClass]="{'--loading': isLoading}">
    <thead class="--sticky">
        <tr>
            <th>{{ 'payment_statements.status' | translate }}</th>
            <th *ngIf="!invoiceAllowed()" [ngClass]="{'--wrap': isEnLang()}">{{ 'payment_statements.payment_date' | translate }}</th>
            <ng-container *ngIf="invoiceAllowed()">
                <th [ngClass]="{'--wrap': isEnLang()}">{{'invoices.invoice_date' | translate}}</th>
                <th [ngClass]="{'--wrap': isEnLang()}">{{'invoices.due_date' | translate}}</th>
            </ng-container>
            <th>{{ 'payment_statements.type' | translate }}</th>
            <th class="--max">{{ 'payment_statements.payment_statement_info' | translate }}</th>
            <th>{{ 'payment_statements.amount' | translate }}</th>
            <th [ngClass]="{'--wrap': isEnLang()}">{{ 'payment_statements.payment_method' | translate }}</th>
            <th [ngClass]="{'--wrap': isEnLang()}">{{ 'payment_statements.payment_instrument' | translate }}</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let payment of filteredPaymentHistory">
            <td>
                <span *ngIf="payment.statusLabel" class="ds-tag" [ngClass]="{'--success': isPaid(payment),  '--icon-confirm': isPaid(payment) || isDeferred(payment), '--info': isDeferred(payment), '--alert': hasError(payment)}"><span>{{payment.statusLabel}}</span></span>
            </td>
            <td *ngIf="!invoiceAllowed()" class="ds-text --small --no-wrap">{{payment.date}}</td>
            <ng-container *ngIf="invoiceAllowed()">
                <td class="ds-text --small">{{payment.invoiceDate}}</td>
                <td class="ds-text --small">{{payment.invoiceDueDate}}</td>
            </ng-container>
            <td class="--center">
                <i *ngIf="isServiceCharge(payment)" class="ds-icon --icon-gauge" [attr.data-ds-texttip]="'paymentHistoryTable.tooltip.serviceCharge' | translate"></i>
                <i *ngIf="isOrder(payment)" class="ds-icon --icon-order" [attr.data-ds-texttip]="'paymentHistoryTable.tooltip.order' | translate"></i>
            </td>
            <td>
                <p *ngIf="isServiceCharge(payment)">{{payment.info}}</p>
                <p *ngIf="isOrder(payment)"><span class="ds-text --xxsmall --label --color-ink-tint">{{'orders.list.orderId' | translate | uppercase}}</span> {{payment.orderId}}</p>
                <p *ngIf="isDeferred(payment)" class="ds-text --xsmall --info">{{'paymentHistoryTable.deferredPayment.warning' | translate}}</p>
                <p *ngIf="payment.errorMessage" class="ds-text --xsmall --alert">{{payment.errorMessage}}</p>
            </td>
            <td class="ds-text --small --no-wrap --right">{{payment.amount | priceAmount: payment.currency}}</td>
            <td class="ds-text --small">{{payment.paymentMethod}}</td>
            <td class="ds-text --small --no-wrap">{{payment.paymentInstrument}}</td>
            <td>
                <details class="ds-menubutton --right --no-background" *ngIf="paymentHasDownloadOptions(payment)">
                    <summary>
                        <span class="ds-button --small --icon-more-horizontal --hide-label">
                        </span>
                    </summary>
                    <div class="__content --mid">
                        <ul>
                            <li *ngIf="canDownloadHistoryEntryInvoice(payment)">
                                <a [href]="isInvoiceDownloading(payment) ? '' : '#'" class="ds-text --icon-download x-invoice-download" (click)="exportInvoice(payment)">{{'paymentHistoryTable.actions.downloadInvoice' | translate}}</a>
                            </li>
                            <li *ngIf="canDownloadHistoryEntryPaymentStatement(payment)">
                                <a [href]="isPaymentStatementDownloading(payment) ? '' : '#'" class="ds-text --icon-download x-payment-statement-download" (click)="exportPaymentStatement(payment)">{{'paymentHistoryTable.actions.downloadPaymentStatement' | translate}}</a>
                            </li>
                            <li *ngIf="canDownloadHistoryEntryServiceCharge(payment)">
                                <a [href]="isServiceChargeCSVDownloading(payment) ? '' : '#'" class="ds-text --icon-download x-service-charge-download" (click)="exportServiceChargeCSV(payment)">{{'paymentHistoryTable.actions.downloadServiceUsageCharges' | translate}}</a>
                            </li>
                        </ul>
                    </div>
                </details>
            </td>
        </tr>
    </tbody>
</table>
</div>