<p class="ds-tag --pinned">{{'billingDashboard.recentBilling.title' | translate}}</p>
<div class="ds-text --xxsmall">{{updatedTimeLabel}}</div>
<ng-container *ngFor="let billingMonth of billingMonths; last as isLast;">
    <ng-container *ngIf="isSelectedBillingMonth(billingMonth); else notSelectedBillingMonth">
        <div class="ds-rows --gap-xsmall --highlight">
            <div class="ds-text">
                <span class="--label">{{billingMonth.monthName}}</span>
                <span class="--uppercase" *ngIf="isCurrentMonth(billingMonth.yearMonth)"> {{ dateRangeInfo(billingMonth) }}</span>
            </div>
            <div *ngIf="isCurrentMonth(billingMonth.yearMonth)" class="--ds-scale --progress" [style.--ds-scale-pc]="monthScaleStyle(billingMonth)"></div>
            <div class="ds-cols --spaced --middle">
                <div class="ds-heading --huge">{{billingMonth.monthlyBillData.amount | priceAmount:'':'':true}}
                  <sup>{{ currencySymbolTranslated(billingMonth.monthlyBillData) }}</sup>
                </div>
                <div class="ds-span --center --dx-tag-temp" *ngIf="billingMonth.monthlyBillData.discountAmount">
                    <div class="ds-text --label --icon-coupon --large">{{ billingMonth.monthlyBillData.discountAmount | priceAmount: currencyUnit }}</div>
                    <div class="ds-text --xsmall">{{ 'billingDashboard.recentBilling.couponApplied' | translate }}</div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #notSelectedBillingMonth>
        <a class="ds-cols --middle" (click)="handleMonthClick(billingMonth)">
            <div class="ds-text ds-span --expand">
                <span class="--label">{{ billingMonth.monthName }}</span>
                <span class="--uppercase" *ngIf="isCurrentMonth(billingMonth.yearMonth)"> {{ dateRangeInfo(billingMonth) }}</span>
                <i class="ds-icon --icon-arrow-top-right --tiny"></i>
            </div>
            <div class="ds-text" *ngIf="billingMonth.monthlyBillData.discountAmount">
                <span class="ds-text --icon-coupon">{{ billingMonth.monthlyBillData.discountAmount | priceAmount : currencyUnit }}</span>
            </div>
            <div class="ds-text --large --8-w-ch --right">{{ billingMonth.monthlyBillData.amount | priceAmount: '':'':true }} <sup> {{ currencySymbolTranslated(billingMonth.monthlyBillData)  }}</sup></div>
        </a>
    </ng-template>
    <hr *ngIf="!isLast">
</ng-container>
<footer>
    <p class="ds-text --xxsmall --icon-coupon">{{ 'billingDashboard.recentBilling.couponInfo' | translate }}</p>
</footer>
