import { LegacyAny } from '@soracom/shared/core';

import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BillingApiService, OrderApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import {
  dateToSortableDate,
  formatNumericDate,
  getMonthAndYearFromYearMonth,
  sortableDateForLastDayOfYearMonth,
} from '@soracom/shared/billings';
import { Logger } from '@soracom/shared/logger';
import { CurrencyCode } from '@soracom/shared/regulatory';
import { GetOrderResponse, MonthlyBill } from '@soracom/shared/soracom-api-typescript-client';
import { BillingType } from '@soracom/shared/soracom-platform';
import { formatDateTime, parseDateTime } from '@soracom/shared/util-common';
import { AuthService } from 'apps/user-console/app/shared/components/auth.service';
import { CoverageTypeService, getCoreService, getCoverageType } from '@soracom/shared/data-access-auth';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { max } from 'lodash-es';
import { PaymentStatement } from '../../../../app/shared/core/payment_statement';
import { AsyncOperationService } from '@user-console/user-console-common';
import { Invoice, InvoicesService } from './invoices.service';
import { PaymentStatementService } from './payment-statement.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class PaymentHistoryService {
  /** Map {orders: orderId -> paymentStatement, monthlyCharges: yearMonth -> paymentStatement} */
  private paymentStatementsMap = {
    orders: new Map<string, PaymentStatement>(),
    monthlyCharges: new Map<string, PaymentStatement>(),
  };
  /** Map {orders: orderId -> invoice, monthlyCharges: yearMonth -> invoice} */
  private invoicesMap = {
    orders: new Map<string, Invoice>(),
    monthlyCharges: new Map<string, Invoice>(),
  };
  /** Map {monthlyCharges: yearMonth -> monthlyBill} */
  private billsMap = {
    monthlyCharges: new Map<string, MonthlyBill>(),
  };
  /** Map {yearMonth -> boolean}: used to access which bills should be marked as 'deferred' */
  private deferredChargeMonthsMap = new Map<string, boolean>();

  // @ts-expect-error (legacy code incremental fix)
  private fetchPaymentHistoryPromise: Promise<PaymentHistoryEntry[]> = null;

  private ordersApi = inject(OrderApiService);

  constructor(
    private paymentStatementsService: PaymentStatementService,
    private translate: TranslateService,
    private asyncOperationService: AsyncOperationService,
    private invoicesService: InvoicesService,
    private soracomApiService: SoracomApiService,
    private billingApiService: BillingApiService,
    private logger: Logger,
  ) {
    getCoreService()
      .LoginUserDataService.loginUserData$.pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.resetCache();
      });
    getCoreService()
      .CoverageTypeService.coverageType$.pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.resetCache();
      });
  }

  /** Fetch the payment statements and cache result in a map for easy access when combining data */
  getPaymentStatements(): Promise<PaymentStatement[]> {
    return this.paymentStatementsService.fetchPaymentStatements().then((paymentStatements) => {
      paymentStatements.forEach((paymentStatement) => {
        if (paymentStatement.billingType === 'ORDER') {
          // @ts-expect-error (legacy code incremental fix)
          this.paymentStatementsMap.orders.set(paymentStatement.orderId, paymentStatement);
        } else if (paymentStatement.billingType === 'MONTHLY_CHARGE') {
          const mostRecentYearMonth = String(max(paymentStatement.chargedYearMonths));
          this.paymentStatementsMap.monthlyCharges.set(mostRecentYearMonth, paymentStatement);
          //Set up deferred month's map. Whether a bill charge is deferred or not is contained in payment statements.
          if (this.paymentStatementIncludesDeferredCharge(paymentStatement)) {
            const deferredChargeYearMonths = this.getDeferredChargeYearMonths(paymentStatement);
            deferredChargeYearMonths.forEach((deferredChargeMonth) => {
              this.deferredChargeMonthsMap.set(deferredChargeMonth, true);
            });
          }
        }
      });
      return paymentStatements;
    });
  }

  /** Fetch the invoices and cache result in a map for easy access when combining data */
  getInvoices(): Promise<Invoice[]> {
    return this.invoicesService.list().then((invoices) => {
      invoices.forEach((invoice) => {
        if (invoice.billingType === 'ORDER') {
          // @ts-expect-error (legacy code incremental fix)
          this.invoicesMap.orders.set(invoice.orderId, invoice);
        } else if (invoice.billingType === 'MONTHLY_CHARGE') {
          const mostRecentYearMonth = String(max(invoice.chargedYearMonths));
          this.invoicesMap.monthlyCharges.set(mostRecentYearMonth, invoice);
        }
      });
      return invoices;
    });
  }

  /**Fetch the bills and cache result in a map for easy access when combining data */
  getBills(): Promise<MonthlyBill[]> {
    return this.soracomApiService.getBills().then((response: LegacyAny) => {
      const billList: MonthlyBill[] = response.data.billList;
      billList.forEach((bill) => {
        const yearMonth = String(bill.yearMonth);
        this.billsMap.monthlyCharges.set(yearMonth, bill);
      });
      return response.data.billList;
    });
  }

  getPaymentHistoryData(): Promise<PaymentHistoryEntry[]> {
    if (this.fetchPaymentHistoryPromise) {
      return this.fetchPaymentHistoryPromise;
    } else {
      this.fetchPaymentHistoryPromise = this.fetchAndPreparePaymentHistoryData().catch((e: LegacyAny) => {
        // @ts-expect-error (legacy code incremental fix)
        this.fetchPaymentHistoryPromise = null;
        throw e;
      });
      return this.fetchPaymentHistoryPromise;
    }
  }

  private fetchAndPreparePaymentHistoryData(): Promise<PaymentHistoryEntry[]> {
    return Promise.all([
      this.getPaymentStatements(),
      this.getInvoices(),
      this.getBills(),
      this.ordersApi.listOrders({}),
    ]).then(([paymentStatements, invoices, bills, orders]) => {
      //Get the bills payment history list from /bills as a base
      const billHistoryEntries: PaymentHistoryEntry[] = bills.map((monthlyBill) => {
        return this.prepareHistoryEntryFromBill(monthlyBill);
      });
      if (billHistoryEntries.length > 0) {
        if (this.getCachedBill(billHistoryEntries[0])?.state === 'temporary') {
          billHistoryEntries.splice(0, 1); //Remove the first Bill entry which is the current month's bill if it is in the temporary state
        }
      }
      let ordersCache = new Map<string, GetOrderResponse>();
      if (orders.data.orderList) {
        ordersCache = new Map(orders.data.orderList.map((o) => [o.orderId as string, o])); // Why the hell soracomApi defines orderId optional 🤯
      }
      // Get the order payment history from /paymentStatements as a base
      const orderHistoryEntries: PaymentHistoryEntry[] = this.prepareOrderHistoryEntryFromPaymentStatementList(
        paymentStatements,
        ordersCache,
      );
      return [...orderHistoryEntries, ...billHistoryEntries].sort(
        (a: LegacyAny, b: LegacyAny) => Number(b.sortDate) - Number(a.sortDate),
      );
    });
  }

  private prepareHistoryEntryFromBill(monthlyBill: MonthlyBill) {
    // @ts-expect-error (legacy code incremental fix)
    const paymentStatement = this.getCachedPaymentStatementByParams('MONTHLY_CHARGE', monthlyBill.yearMonth);
    // @ts-expect-error (legacy code incremental fix)
    const invoice = this.getCachedInvoiceByParams('MONTHLY_CHARGE', monthlyBill.yearMonth);
    const billHistoryEntry: PaymentHistoryEntry = {
      // @ts-expect-error (legacy code incremental fix)
      currency: monthlyBill.currency,
      // @ts-expect-error (legacy code incremental fix)
      status: monthlyBill.paymentStatus,
      statusLabel: this.getPaymentStatusTextFromMonthlyBill(monthlyBill),
      date:
        (getCoverageType() === 'jp'
          ? this.parsePaymentStatementDate(paymentStatement)
          : this.parseInvoiceDate(invoice?.invoiceDate)) ?? '',
      sortDate:
        (getCoverageType() === 'jp'
          ? this.sortablePaymentStatementDate(paymentStatement)
          : // @ts-expect-error (legacy code incremental fix)
            this.sortableInvoiceDate(invoice)) ?? sortableDateForLastDayOfYearMonth(monthlyBill.yearMonth),
      type: 'MONTHLY_CHARGE',
      // @ts-expect-error (legacy code incremental fix)
      info: paymentStatement?.paymentStatementInfo ?? this.defaultMonthlyBillInfo(monthlyBill.yearMonth),
      paymentMethod: paymentStatement?.paymentMethod,
      paymentInstrument: paymentStatement?.paymentInstrument,
      // @ts-expect-error (legacy code incremental fix)
      amount: monthlyBill.amount,
      hasInvoice: !!invoice,
      hasPaymentStatement: !!paymentStatement,
      invoiceDate: formatNumericDate(invoice?.invoiceDate),
      invoiceDueDate: formatNumericDate(invoice?.dueDate),
      monthlyChargeYearMonth: monthlyBill.yearMonth,
    };
    // @ts-expect-error (legacy code incremental fix)
    if (this.deferredChargeMonthsMap.get(monthlyBill.yearMonth)) {
      this.updateDeferredBillHistoryEntry(billHistoryEntry);
    }
    this.fetchMonthlyBillErrorMessage(billHistoryEntry);
    return billHistoryEntry;
  }

  private updateDeferredBillHistoryEntry(deferredChargeHistEntry: PaymentHistoryEntry) {
    const status = 'deferred';
    deferredChargeHistEntry.status = status;
    deferredChargeHistEntry.statusLabel = this.translate.instant(`paymentHistoryTable.status.${status}`);
  }

  private prepareOrderHistoryEntryFromPaymentStatementList(
    paymentStatements: PaymentStatement[],
    orders?: Map<string, GetOrderResponse>,
  ) {
    return paymentStatements.reduce(
      (orderHistoryEntries, paymentStatement) => {
        // @ts-expect-error (legacy code incremental fix)
        const invoice = this.getCachedInvoiceByParams(paymentStatement.billingType, paymentStatement.orderId);
        if (paymentStatement.billingType === 'ORDER') {
          const foundOrder = paymentStatement?.orderId ? orders?.get(paymentStatement.orderId) : undefined; // Will invoide have orderId undefined ?? who knows ...
          let paymentStatus = '';
          if (foundOrder?.paymentStatus) {
            paymentStatus = foundOrder.paymentStatus;
          }
          const orderHistoryEntry: PaymentHistoryEntry = {
            // @ts-expect-error (legacy code incremental fix)
            currency: paymentStatement.currency,
            status: paymentStatus || invoice?.status,
            statusLabel: paymentStatus
              ? this.translate.instant(`paymentHistoryTable.status.${paymentStatus}`)
              : this.getPaymentStatusTextFromInvoice(invoice),
            date:
              getCoverageType() === 'jp'
                ? this.parsePaymentStatementDate(paymentStatement)
                : this.parseInvoiceDate(invoice?.invoiceDate),
            sortDate:
              getCoverageType() === 'jp'
                ? this.sortablePaymentStatementDate(paymentStatement)
                : this.sortableInvoiceDate(invoice),
            type: paymentStatement.billingType,
            info: paymentStatement.paymentStatementInfo,
            paymentMethod: paymentStatement.paymentMethod,
            paymentInstrument: paymentStatement.paymentInstrument,
            amount: paymentStatement.amount,
            hasInvoice: !!invoice,
            hasPaymentStatement: !!paymentStatement,
            invoiceDate: formatNumericDate(invoice?.invoiceDate),
            invoiceDueDate: formatNumericDate(invoice?.dueDate),
            orderId: paymentStatement.orderId,
          };
          orderHistoryEntries.push(orderHistoryEntry);
        }
        return orderHistoryEntries;
      },
      <PaymentHistoryEntry[]>[],
    );
  }

  // @ts-expect-error (legacy code incremental fix)
  private sortablePaymentStatementDate(paymentStatement: PaymentStatement): string {
    if (paymentStatement) {
      // @ts-expect-error (legacy code incremental fix)
      return dateToSortableDate(this.parsePaymentStatementDate(paymentStatement));
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  private sortableInvoiceDate(invoice: Invoice): string {
    if (invoice) {
      return invoice.invoiceDate;
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  private parsePaymentStatementDate(paymentStatement: PaymentStatement): string {
    if (paymentStatement) {
      // @ts-expect-error (legacy code incremental fix)
      return formatDateTime(paymentStatement.paymentDateTime * 1000, 'date');
    }
  }

  /** Parses dates in YYYYMMDD format to YYYY-MM-DD */
  // @ts-expect-error (legacy code incremental fix)
  private parseInvoiceDate(date: string): string {
    if (date) {
      // @ts-expect-error (legacy code incremental fix)
      return formatDateTime(parseDateTime(date, 'YYYYMMDD'), 'date');
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  private paymentStatementIncludesDeferredCharge(paymentStatement: PaymentStatement): boolean {
    if (paymentStatement) {
      // @ts-expect-error (legacy code incremental fix)
      return paymentStatement.chargedYearMonths?.length > 1;
    }
  }

  private getDeferredChargeYearMonths(paymentStatement: PaymentStatement): string[] {
    const mostRecentYearMonth = max(paymentStatement.chargedYearMonths);
    // @ts-expect-error (legacy code incremental fix)
    return paymentStatement.chargedYearMonths.filter((yearMonth) => yearMonth !== mostRecentYearMonth);
  }

  exportPaymentStatement(paymentHistoryEntry: PaymentHistoryEntry) {
    const fullDescription = this.paymentStatementPdfTitle(paymentHistoryEntry);
    return this.asyncOperationService.handleExportAsyncWithSyncFallback(fullDescription, (asyncAllowed: LegacyAny) => {
      const paymentStatementId = this.getCachedPaymentStatement(paymentHistoryEntry).paymentStatementId;
      return this.paymentStatementsService.exportPaymentStatement(paymentStatementId, asyncAllowed);
    });
  }

  isPaymentStatementDownloading(paymentHistoryEntry: PaymentHistoryEntry) {
    const fullDescription = this.paymentStatementPdfTitle(paymentHistoryEntry);
    return this.asyncOperationService.isOperationAlreadyInProgress(fullDescription);
  }

  paymentStatementPdfTitle(paymentHistoryEntry: PaymentHistoryEntry) {
    const paymentStatement = this.getCachedPaymentStatement(paymentHistoryEntry);
    return `${this.translate.instant('payment_statements.export')}: ${paymentStatement?.paymentStatementInfo}`;
  }

  exportInvoice(paymentHistoryEntry: PaymentHistoryEntry) {
    const invoice = this.getCachedInvoice(paymentHistoryEntry);
    const identifier = invoice.invoiceId;
    const fullDescription = this.invoicePdfTitle(paymentHistoryEntry);
    this.asyncOperationService.handleExportAsyncWithSyncFallback(fullDescription, (asyncAllowed: LegacyAny) => {
      return this.invoicesService.export(identifier, asyncAllowed, invoice.existDetailedPDF);
    });
  }

  isInvoiceDownloading(paymentHistoryEntry: PaymentHistoryEntry) {
    const fullDescription = this.invoicePdfTitle(paymentHistoryEntry);
    return this.asyncOperationService.isOperationAlreadyInProgress(fullDescription);
  }

  invoicePdfTitle(paymentHistoryEntry: PaymentHistoryEntry) {
    const invoice = this.getCachedInvoice(paymentHistoryEntry);
    return `${this.translate.instant('invoices.export')}: ${invoice?.invoiceId}`;
  }

  exportMonthlyBillCsv(paymentHistoryEntry: PaymentHistoryEntry) {
    const bill = this.getCachedBill(paymentHistoryEntry);
    this.asyncOperationService.handleExportAsyncWithSyncFallback(
      this.billCSVDownloadTitle(paymentHistoryEntry),
      (asyncAllowed: LegacyAny) => {
        return this.billingApiService.exportBilling({
          // @ts-expect-error (legacy code incremental fix)
          yyyyMM: bill.yearMonth,
          exportMode: asyncAllowed ? 'async' : 'sync',
        });
      },
    );
  }

  isBillCSVDownloading(paymentHistoryEntry: PaymentHistoryEntry) {
    return this.asyncOperationService.isOperationAlreadyInProgress(this.billCSVDownloadTitle(paymentHistoryEntry));
  }

  billCSVDownloadTitle(paymentHistoryEntry: PaymentHistoryEntry) {
    return `${this.translate.instant('billing_details.download_csv_data')}(${
      paymentHistoryEntry.monthlyChargeYearMonth
    })`;
  }

  private getPaymentStatusTextFromMonthlyBill(bill: MonthlyBill) {
    const paymentStatus = bill.paymentStatus;
    const billState = bill.state;

    // @ts-expect-error (legacy code incremental fix)
    let localizationId: string = paymentStatus; // usually

    if (billState === 'temporary') {
      localizationId = 'temporary'; // added 2019
    } else if (localizationId === undefined || localizationId === null) {
      localizationId = 'beforeCharging';
    }

    const leading = 'billings.history.table.payment_status.';
    switch (localizationId) {
      case 'beforeCharging':
      case 'fail':
      case 'lessThanMinimumCharge':
      case 'paid':
      case 'paying':
      case 'refunded':
      case 'refunding':
      case 'temporary':
      case 'refund_fail':
      case 'delegating_to_third_party':
      case 'delegated_to_third_party':
      case 'charging':
        return this.translate.instant(leading + localizationId);
      default:
        return localizationId;
    }
  }

  private getPaymentStatusTextFromInvoice(invoice: Invoice) {
    if (invoice) {
      const paymentStatus = invoice.status;
      return this.translate.instant(`paymentHistoryTable.status.${paymentStatus}`);
    }
  }

  /**To be used when no payment statement info is available for a monthly charge. */
  private defaultMonthlyBillInfo(yearMonth: string) {
    const { year, month } = getMonthAndYearFromYearMonth(yearMonth);
    const monthName = this.translate.instant(`date.monthName_v2.${month}`);
    const yearMonthWritten = this.translate.instant('date.yearMonthWritten', { year: year, monthName: monthName });
    return this.translate.instant('paymentHistoryTable.monthlyCharge.defaultMessage', {
      yearMonth: yearMonthWritten,
    });
  }

  private fetchMonthlyBillErrorMessage(paymentHistoryEntry: PaymentHistoryEntry) {
    const bill = this.getCachedBill(paymentHistoryEntry);
    if (bill?.paymentStatus !== 'fail') {
      return;
    }

    const ptid = bill.paymentTransactionId;
    this.soracomApiService
      .getPaymentDetails(ptid)
      .then((response: LegacyAny) => {
        paymentHistoryEntry.errorMessage = response?.data?.message;
      })
      .catch((err: LegacyAny) => {
        this.logger.debug('getPaymentDetails returns an error.', err);
      });
  }

  // @ts-expect-error (legacy code incremental fix)
  private getCachedPaymentStatement(paymentHistoryEntry: PaymentHistoryEntry): PaymentStatement {
    if (paymentHistoryEntry.type === 'ORDER') {
      // @ts-expect-error (legacy code incremental fix)
      return this.paymentStatementsMap.orders.get(paymentHistoryEntry.orderId);
    } else if (paymentHistoryEntry.type === 'MONTHLY_CHARGE') {
      // @ts-expect-error (legacy code incremental fix)
      return this.paymentStatementsMap.monthlyCharges.get(paymentHistoryEntry.monthlyChargeYearMonth);
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  private getCachedPaymentStatementByParams(type: BillingType, identifier: string): PaymentStatement {
    if (type === 'ORDER') {
      // @ts-expect-error (legacy code incremental fix)
      return this.paymentStatementsMap.orders.get(identifier);
    } else if (type === 'MONTHLY_CHARGE') {
      // @ts-expect-error (legacy code incremental fix)
      return this.paymentStatementsMap.monthlyCharges.get(identifier);
    }
  }
  // @ts-expect-error (legacy code incremental fix)

  private getCachedInvoice(paymentHistoryEntry: PaymentHistoryEntry): Invoice {
    if (paymentHistoryEntry.type === 'ORDER') {
      // @ts-expect-error (legacy code incremental fix)
      return this.invoicesMap.orders.get(paymentHistoryEntry.orderId);
    } else if (paymentHistoryEntry.type === 'MONTHLY_CHARGE') {
      // @ts-expect-error (legacy code incremental fix)
      return this.invoicesMap.monthlyCharges.get(paymentHistoryEntry.monthlyChargeYearMonth);
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  private getCachedInvoiceByParams(type: BillingType, identifier: string): Invoice {
    if (type === 'ORDER') {
      // @ts-expect-error (legacy code incremental fix)
      return this.invoicesMap.orders.get(identifier);
    } else if (type === 'MONTHLY_CHARGE') {
      // @ts-expect-error (legacy code incremental fix)
      return this.invoicesMap.monthlyCharges.get(identifier);
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  private getCachedBill(paymentHistoryEntry: PaymentHistoryEntry): MonthlyBill {
    if (paymentHistoryEntry.type === 'MONTHLY_CHARGE') {
      // @ts-expect-error (legacy code incremental fix)
      return this.billsMap.monthlyCharges.get(paymentHistoryEntry.monthlyChargeYearMonth);
    }
  }

  private resetCache() {
    // @ts-expect-error (legacy code incremental fix)
    this.fetchPaymentHistoryPromise = null;
    this.paymentStatementsMap = {
      orders: new Map<string, PaymentStatement>(),
      monthlyCharges: new Map<string, PaymentStatement>(),
    };
    this.invoicesMap = {
      orders: new Map<string, Invoice>(),
      monthlyCharges: new Map<string, Invoice>(),
    };
    this.billsMap = {
      monthlyCharges: new Map<string, MonthlyBill>(),
    };
    this.deferredChargeMonthsMap = new Map<string, boolean>();
  }
}

export interface PaymentHistoryEntry {
  status: PaymentHistoryStatus;
  statusLabel: string;
  date: string;
  sortDate: string;
  type: BillingType;
  info: string;
  paymentMethod: string;
  paymentInstrument: string;
  amount: number;
  hasInvoice: boolean;
  hasPaymentStatement: boolean;
  currency: CurrencyCode;
  orderId?: string;
  monthlyChargeYearMonth?: string;
  invoiceDate?: string;
  invoiceDueDate?: string;
  errorMessage?: string;
}

export type PaymentHistoryStatus =
  | 'paid'
  | 'aggregating'
  | 'deferred'
  | 'delegating_to_third_party'
  | 'delegated_to_third_party'
  | string;
