import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { normalizeMonthOrDayNumber } from '@soracom/shared/util-common';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

/**Provides util functions for date calculation/manipulation.  Serves as a buffer for dayjs.js() so that components don't need to use the dayjs interface directly.
 * Also provides convenience functions to retrieve translated date strings */
@Injectable({ providedIn: 'root' })
export class SoracomDateService {
  constructor(private translateService: TranslateService) {}

  /**
   * Get date data for the current date.
   * @returns {DateData}
   */
  getCurrentDateData(): DateData {
    return this.getDateData(
      dayjs().year(),
      dayjs().month() + 1,
      dayjs().date(),
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param day
   * @returns {DateData}
   */
  getDateData(
    year: number | string,
    month: number | string,
    day: number | string,
  ): DateData {
    const dayjsDate = dayjs(
      `${year}-${normalizeMonthOrDayNumber(month)}-${normalizeMonthOrDayNumber(
        day,
      )}`,
      'YYYY-MM-DD',
    );
    const yearNumber = dayjsDate.year();
    const monthNumber = dayjsDate.month() + 1; //dayjs dates are 0-11, want to be 1-12
    const dateData: DateData = {
      year: yearNumber,
      month: monthNumber,
      yearMonth: `${yearNumber}${normalizeMonthOrDayNumber(monthNumber)}`,
      monthName: this.translateService.instant('date.monthName.' + monthNumber),
      finalDay: dayjsDate.date(),
      totalDays: dayjsDate.daysInMonth(),
    };
    return dateData;
  }

  /** Convert any date format to DateData
   * @param {string} date - date string in any format
   * @param {string} dateFormat - The format of the date string used by dayjs to parse the date.  For example: YYYY-MM-DD
   */
  getDateDataFromFormat(date: string, dateFormat: string): DateData {
    const dayjsDate = dayjs(date, dateFormat);
    return this.getDateData(
      dayjsDate.year(),
      dayjsDate.month() + 1,
      dayjsDate.date(),
    );
  }

  /**
   *
   * @param {string} yearMonth - YYYYMM
   * @param {number} monthOffset - number of months to offset from the given yearMonth.  Defaults to 1.
   * @returns {DateData}
   */
  getPreviousMonthData(yearMonth: string, monthOffset: number = 1): DateData {
    const dayjsDate = dayjs(yearMonth, 'YYYYMM');
    const previousDayjsDate = dayjsDate
      .subtract(monthOffset, 'months')
      .endOf('month');
    return this.getDateData(
      previousDayjsDate.year(),
      previousDayjsDate.month() + 1,
      previousDayjsDate.date(),
    );
  }

  /**
   *
   * @param {string} yearMonth - YYYYMM
   * @param {number} monthOffset - number of months to offset from the given yearMonth.  Defaults to 1.
   * @returns {DateData}
   */
  getNextMonthData(yearMonth: string, monthOffset: number = 1): DateData {
    const dayjsDate = dayjs(yearMonth, 'YYYYMM');
    const previousDayjsDate = dayjsDate
      .add(monthOffset, 'months')
      .endOf('month');
    return this.getDateData(
      previousDayjsDate.year(),
      previousDayjsDate.month() + 1,
      previousDayjsDate.date(),
    );
  }

  /** Get a i18n string of the format '1 August - 26 August'.  Will default to current month and date. */
  getTranslatedDateRange(month?: number, finalDay?: number) {
    const monthName = month
      ? this.translateService.instant(`date.monthName.${month}`)
      : this.getCurrentDateData().monthName;
    finalDay = finalDay ?? this.getCurrentDateData().finalDay;
    return finalDay > 1
      ? this.translateService.instant('date.dateRange', {
          startDay: 1,
          endDay: finalDay,
          monthName: monthName,
        })
      : this.translateService.instant('date.dateRangeFirstDay', {
          monthName: monthName,
        });
  }
}

export interface DateData {
  month: number;
  monthName: string; //pre-translated for convenience
  year: number;
  yearMonth: string;
  finalDay: number;
  totalDays: number;
}
