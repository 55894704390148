import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { StLoadingIndicator, StRemoteApiErrorHandler, StTableDataProvider } from '@soracom/shared-ng/ui-super-table';
import { ExtendedSim as Sim } from '@soracom/shared/sim';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { CostPerSimData } from '../../billing-dashboard-data.service';
import { ExtendedSimApiService } from '@soracom/shared-ng/sim-services';

@Injectable()
export class CostPerSimDataService implements StTableDataProvider<CostPerSimTableData> {
  public costPerSimData: CostPerSimData[] = [];
  private simCache = new Map<string, Sim>();
  private loading$: BehaviorSubject<boolean>;

  displayItems$: Observable<readonly CostPerSimTableData[]>;

  constructor(
    upstream: StTableDataProvider<CostPerSimData>,
    stLoadingIndicator: StLoadingIndicator,
    private apiErrorHandler: StRemoteApiErrorHandler,
    private queryApi: ExtendedSimApiService
  ) {
    this.displayItems$ = upstream.displayItems$.pipe(switchMap((data) => this.gatherCostPerSimTableData(data)));
    this.loading$ = stLoadingIndicator.loading$ as BehaviorSubject<boolean>;
  }

  private gatherCostPerSimTableData(costPerSimData: readonly CostPerSimData[]): Promise<CostPerSimTableData[]> {
    const nonCachedSimIds = costPerSimData
      .map((costPerSimData) => costPerSimData.simId)
      .filter((simId) => !this.simCache.has(simId));
    // @ts-expect-error (legacy code incremental fix)
    return this.fetchSimData(nonCachedSimIds).then((sims) => {
      sims.forEach((sim) => {
        this.simCache.set(sim.simId, sim);
      });
      return costPerSimData.map((costPerSimData) => {
        return {
          name: this.simCache.get(costPerSimData.simId)?.tags?.name,
          groupId: this.simCache.get(costPerSimData.simId)?.groupId,
          amount: costPerSimData.amount,
          simId: costPerSimData.simId,
        };
      });
    });
  }

  private fetchSimData(simIds: string[]): Promise<Sim[]> {
    //FIXME - this should use SATC, but the SATC function for this api endpoint is not working at the moment.  Going to use this not so clean workaround for the time being
    if (simIds?.length > 0) {
      this.loading$.next(true);
      return this.queryApi
        .searchSims({
          simId: simIds,
        })
        .catch((error: LegacyAny) => {
          this.apiErrorHandler.handleApiError(error);
          return [];
        })
        .finally(() => this.loading$.next(false));
    } else {
      return Promise.resolve([]);
    }
  }
}

export interface CostPerSimTableData extends CostPerSimData {
  name: string;
  groupId: string;
}
